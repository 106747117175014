/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { getUuid } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material'
import { MoreVertRounded } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import NumberField from 'components/FieldWithInputAdornment/NumberField'

/* Type imports ------------------------------------------------------------- */
import type { Decote } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 20px;
  gap: 5px;
  padding: 5px;
  align-items: center;
`

const IconButton = styled(Button)`
  align-self: center;
  min-width: auto;
  width: 20px !important;
`

/* Component declaration ---------------------------------------------------- */
interface MeasureDecoteLineProps {
  lines: Decote[];
  setFieldValue: (field: string, value: string | Decote | Decote[] | boolean | number) => void;
  index: number;
  lineName: string;
  linesName: string;
}

const MeasureDecoteLine: React.FC<MeasureDecoteLineProps> = ({
  lines,
  setFieldValue,
  index,
  lineName,
  linesName,
}) => {
  const [ anchorLineMenu, setAnchorLineMenu ] = useState<null | SVGElement>(null)

  const handleLineMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    event.stopPropagation()
    setAnchorLineMenu(event.currentTarget)
  }

  const handleLineMenuClose = (): void => {
    setAnchorLineMenu(null)
  }

  const onDuplicateClick = () => {
    if (!lines?.length) return
    const newArray = [ ...lines ]
    const duplicateItem = { ...lines[index], id: getUuid() }

    newArray.splice(index + 1, 0, duplicateItem)
    setFieldValue(linesName, newArray)
    handleLineMenuClose()
  }

  const onDeleteClick = () => {
    if (!lines?.length) return
    const newArray = [ ...lines ]

    newArray.splice(index, 1)
    setFieldValue(linesName, newArray)
    handleLineMenuClose()
  }

  return (
    <GridContainer>
      <div>
        Décote
      </div>
      <Field
        component={TextField}
        name={`${lineName}.libelle`}
        size="small"
        placeholder="Libellé"
      />
      <NumberField
        name={`${lineName}.longueur`}
        onChange={(e) => setFieldValue(`${lineName}.longueur`, e.target.value)}
        size="small"
      />
      <NumberField
        name={`${lineName}.largeur`}
        onChange={(e) => setFieldValue(`${lineName}.largeur`, e.target.value)}
        size="small"
      />
      <IconButton
        variant="text"
        size="small"
      >
        <MoreVertRounded
          fontSize="large"
          onClick={handleLineMenuClick}
        />
      </IconButton>
      <Menu
        anchorEl={anchorLineMenu}
        open={Boolean(anchorLineMenu)}
        onClose={handleLineMenuClose}
        onClick={(e): void => e.stopPropagation()}
      >
        <MenuItem onClick={onDuplicateClick}>
          Dupliquer
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          Supprimer
        </MenuItem>
      </Menu>
    </GridContainer>
  )
}

export default MeasureDecoteLine
