/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { Theme } from '@emotion/react'

/* Type declarations -------------------------------------------------------- */
export type ChipColor = 'green' |
                        'orange' |
                        'darkorange' |
                        'red' |
                        'pink' |
                        'salmon' |
                        'yellow' |
                        'purple' |
                        'lightblue' |
                        'blue' |
                        'grey' |
                        'lightgrey' |
                        'darkgrey' |
                        'warning' |
                        'primary' |
                        'secondary'

const isValidHexColor = (stringToVerify?: string | null): boolean => {
  const hexColorPattern = /^#([0-9A-Fa-f]{3}){1,2}$/

  if (!stringToVerify || stringToVerify?.length !== 7 || !hexColorPattern.test(stringToVerify)) {
    return false
  }
  return true
}

const hexToHsl = (hex: string) => {
  const r = parseInt(hex.substring(1, 3), 16) / 255
  const g = parseInt(hex.substring(3, 5), 16) / 255
  const b = parseInt(hex.substring(5, 7), 16) / 255

  const max = Math.max(r, g, b),
        min = Math.min(r, g, b)
  let h = 0,
      s = 0
  const l = (max + min) / 2

  if (max !== min) {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    if (max === r) h = (g - b) / d + (g < b ? 6 : 0)
    else if (max === g) h = (b - r) / d + 2
    else if (max === b) h = (r - g) / d + 4
    h *= 60
  }
  return { h, s, l }
}

const hslToHex = (h: number, s: number, l: number) => {
  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs((h / 60) % 2 - 1))
  const m = l - c / 2
  let r = 0,
      g = 0,
      b = 0

  if (h < 60) [ r, g, b ] = [ c, x, 0 ]
  else if (h < 120) [ r, g, b ] = [ x, c, 0 ]
  else if (h < 180) [ r, g, b ] = [ 0, c, x ]
  else if (h < 240) [ r, g, b ] = [ 0, x, c ]
  else if (h < 300) [ r, g, b ] = [ x, 0, c ]
  else [ r, g, b ] = [ c, 0, x ]

  const toHex = (value: number) =>
    Math.round((value + m) * 255)
      .toString(16)
      .padStart(2, '0')

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

const enhanceColor = (hex: string, saturationBoost: number, lightnessAdjust: number) => {
  // eslint-disable-next-line prefer-const
  let { h, s, l } = hexToHsl(hex)
  s = Math.min(1, s * saturationBoost)
  l = Math.max(0, Math.min(1, l + lightnessAdjust))
  return hslToHex(h, s, l)
}

export const getChipColor = (color: ChipColor, customColor: string, theme: Theme): string => {

  const cleanColor = isValidHexColor(customColor) ? customColor : color

  switch (cleanColor) {
    case 'green':
      return '#DAF4D4'
    case 'orange':
      return '#FBE7D3'
    case 'darkorange':
      return '#ffd48f'
    case 'red':
      return '#e86666'
    case 'salmon':
      return '#FFC0BC'
    case 'pink':
      return '#ffb1d8'
    case 'yellow':
      return '#fff9a6'
    case 'purple':
      return '#D7CAFB'
    case 'lightblue':
      return '#D6ECEE'
    case 'blue':
      return '#D4E0FD'
    case 'grey':
      return theme.colors.grey
    case 'lightgrey':
      return theme.colors.lightgrey
    case 'darkgrey':
      return theme.colors.darkgrey
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    case 'warning':
      return theme.palette.warning.main
    default:
      return cleanColor
  }
}
/* Component declaration ---------------------------------------------------- */
export interface ColoredSquareChipProps {
  variant?: 'outlined' | 'filled';
  color: ChipColor;
  customColor?: string | null;
  bold?: boolean;
  smaller?: boolean;
}

const ColoredSquareChip = styled.div<ColoredSquareChipProps>`
  background: ${(props) => props.variant === 'outlined' ? props.theme.colors.main : getChipColor(props.color, props.customColor || '', props.theme)};
  color: ${(props) => props.variant === 'outlined' ? enhanceColor(getChipColor(props.color, props.customColor || '', props.theme), 0.8, -0.4) : '#000000'};
  border: ${(props) => props.variant === 'outlined' ? `2px solid ${getChipColor(props.color, props.customColor || '', props.theme)}` : ''};
  font-weight: ${(props) => props.bold ? 'bold' : 'initial'};
  text-align: center;
  align-self: center;
  width: fit-content;
  height: auto;
  border-radius: ${(props) => props.smaller ? '3px' : '4px'};
  padding: ${(props) => props.smaller ? '3px' : '5px'} 7px;
  font-size: ${(props) => props.smaller ? '12px' : '14px'};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
`

export default ColoredSquareChip
