/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import Table from 'components/Table/Table'
import CaseTaskMobileTableCard from './CaseTaskMobileTableCard'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'
import type {
  ColumnHeader,
  Order,
} from 'components/Table/Table'

/* Styled components -------------------------------------------------------- */
const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 15px 5px;

  .status-chip {
    width: 100%;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: block;
    min-height: 200px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseTaskTableProps {
  filteredList: PieceJointe[];
  isFetchingList: boolean;
  setFilteredList: (value: PieceJointe[]) => void;
  setOrder: (newOrder: Order) => void;
  setOrderBy: (newOrderBy: string) => void;
}

const CaseTaskTable: React.FC<CaseTaskTableProps> = ({
  filteredList,
  isFetchingList,
  setFilteredList,
  setOrder,
  setOrderBy,
}) => {
  const cols: ColumnHeader[] = [
    {
      id: 'echeance',
      label: 'Échéance',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'type',
      label: 'Type',
    },
    {
      id: 'libelle',
      label: 'Libellé',
    },
    {
      id: 'etat',
      label: 'Destinataire',
    },
    {
      id: 'id',
      label: 'Actions',
      removeFromExport: true,
      render: () => (
        <div>
          <Button variant="outlined">
            Transférer
          </Button>
          <Button variant="outlined">
            Reporter
          </Button>
          <Button variant="contained">
            Solder
          </Button>
        </div>
      ),
    },
  ]

  return (
    <LoadingOverlay isLoading={isFetchingList}>
      <TableCardContainer>
        <TableCardContentContainer>
          <Table
            rows={filteredList}
            setRows={(rows) => setFilteredList(rows)}
            colHeaders={cols}
            limit={25}
            defaultOrder={{ order: 'desc', orderBy: 'dateCreation' }}
            sorting={{ setOrder, setOrderBy }}
          />
        </TableCardContentContainer>
      </TableCardContainer>
      <MobileCardContainer>
        {
          filteredList.map((file, index) => (
            <CaseTaskMobileTableCard
              key={`${file.id}-${index}`}
              file={file}
            />
          ))
        }
      </MobileCardContainer>
    </LoadingOverlay>
  )
}

export default CaseTaskTable
