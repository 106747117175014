/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import JSZip from 'jszip'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useAppDispatch } from 'store/hooks'
import {
  addDocument,
  setOpenMedia,
} from 'store/slices/layoutSlice'
// import { getRowsComparator } from 'components/Table/Table'
import {
  downloadFile,
  getCleanFilename,
} from 'helpers/mediaUtils'
import {
  useGetCaseMediaListQuery,
  usePostMediaFilesMutation,
  useGetMediaCategoryListQuery,
} from 'store/api'
import { getMediaFileType } from 'helpers/getMediaFileType'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
} from '@mui/material'
import {
  AddPhotoAlternateOutlined,
  Close,
  Download,
  Edit,
  EditOff,
  FilterList,
  OpenInNew,
  // SwapVert,
} from '@mui/icons-material'
import { Select } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ImportFilesModal from 'components/ImportFilesModal/ImportFilesModal'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import CaseLayoutMediaThumbnail from './CaseLayoutMediaThumbnail'
import CaseLayoutMediaFileDisplay from './CaseLayoutMediaFileDisplay'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
} from 'formik'
import type {
  DataName,
  Order,
} from 'components/Table/Table'
import type {
  MediathequeDossierCreatePayload,
  ElementMediatheque,
} from 'API/__generated__/Api'
import { MediathequeType } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const documentsSchema = Yup.object({
  filters: Yup.object({
    category: Yup.string(),
    types: Yup.array(Yup.mixed<MediathequeType>().required()).required(),
  }).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type DocumentsSchema = Yup.InferType<typeof documentsSchema>

type DocumentsForm = FormikContextType<DocumentsSchema>

type SubMenu = 'sort' | 'filter'

/* Styled components -------------------------------------------------------- */
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
`

interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const BoldSeparator = styled(Divider)`
  margin: 5px 0px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const ViewerContainer = styled.div`
  height: calc(100vh - 100px);
  overflow-y: auto;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

const NoMediaContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutMediaProps {}

const CaseLayoutMedia: React.FC<CaseLayoutMediaProps> = () => {
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ selected, setSelected ] = useState<ElementMediatheque[]>([])
  const [ edit, setEdit ] = useState<boolean>(false)
  const [ filteredDocumentList, setFilteredDocumentList ] = useState<ElementMediatheque[]>([])
  const [ openUpload, setOpenUpload ] = useState<boolean>(false)
  const [ openSubMenu, setOpenSubMenu ] = useState<SubMenu | null>(null)
  const [ displayLarge, setDisplayLarge ] = useState<ElementMediatheque | null>(null)

  const formikForm: DocumentsForm = useForm<DocumentsSchema>(
    {
      initialValues: {
        filters: {
          types: [],
          category: '',
        },
        sort: {
          order: 'desc',
          orderBy: 'dateCreation',
        },
      },
      validationSchema: documentsSchema,
    },
  )

  const {
    currentData: documentList = [],
    isFetching: isFetchingDocumentList,
  } = useGetCaseMediaListQuery({ dossier: caseId, Categorie: formikForm.values.filters.category, Types: formikForm.values.filters.types || []})
  const {
    currentData: categoryList = [],
    isFetching: isFetchingCategoryList,
  } = useGetMediaCategoryListQuery()
  const [
    submitNewDocument,
    { isLoading: isSendingFile },
  ] = usePostMediaFilesMutation()

  useEffect(() => {
    if (!isFetchingDocumentList) {
      setFilteredDocumentList([ ...documentList ])
    }
  }, [ isFetchingDocumentList, caseId, documentList ])

  // useEffect(() => {
  //   const { types = []} = formikForm.values.filters
  //   const { order, orderBy } = formikForm.values.sort

  //   setFilteredDocumentList([ ...documentList ].sort(getRowsComparator(order, orderBy)).filter((document) =>
  //     (types.length === 0 || types.includes(document.categorie?.code)),
  //   ))
  // }, [ formikForm.values.filters ])

  const onClose = () => {
    dispatch(setOpenMedia(false))
  }

  const onCloseUpload = (files?: File[]) => {
    setOpenUpload(false)
    const data: MediathequeDossierCreatePayload = { elementMediathequeRequest: []}

    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file !== undefined) {
          data.elementMediathequeRequest?.push({
            fichier: file,
            nom: file.name,
          })
        }
      })

      submitNewDocument({
        caseId,
        data,
      }).catch(console.error)
    }
  }

  const openOutsideDocument = (doc: ElementMediatheque) => {
    const maxWindowWidth = window.innerWidth * 0.75
    const maxWindowHeight = window.innerHeight * 0.75

    if (getMediaFileType(doc) === 'img') {
      const img = new Image()
      img.src = doc.url || ''

      img.onload = () => {
        const { width, height } = img
        let finalSize = { width, height }

        if (width <= maxWindowWidth && height <= maxWindowHeight) {
          finalSize = { width, height }
        } else {
          const widthRatio = maxWindowWidth / width
          const heightRatio = maxWindowHeight / height
          const scale = Math.min(widthRatio, heightRatio)

          finalSize = {
            width: Math.floor(width * scale),
            height: Math.floor(height * scale),
          }
        }
        dispatch(addDocument({ caseId, document: doc, height: finalSize.height + 30, width: finalSize.width, edit }))
      }

    } else {
      dispatch(addDocument({ caseId, document: doc, height: maxWindowHeight, edit }))
    }
  }

  const openLargeDocument = (document: ElementMediatheque) => {
    setDisplayLarge(document)
    setOpenSubMenu(null)
  }

  const onClickOpenSubMenu = (value: SubMenu) => {
    if (value === openSubMenu) {
      setOpenSubMenu(null)
    } else {
      setOpenSubMenu(value)
    }
  }

  const onDownloadButtonClick = () => {
    const zip = new JSZip()
    const files = selected.length > 0 ? selected : filteredDocumentList

    files.forEach((file) => {
      if (!file.url) {
        return
      }
      const blobPromise = fetch(file.url).then((r) => {
        if (r.status === 200) {
          return r.blob()
        }
        throw Promise.reject(new Error(r.statusText))
      })
      zip.file(getCleanFilename(file.libelle, file.fileName), blobPromise)
    })
    zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, `${caseId}_mediathèque`)).catch(console.error)
  }

  const onFileDonwloadClick = (document: ElementMediatheque) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    downloadFile(getCleanFilename(document.libelle, document.fileName || ''), document.url)
  }

  // const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)
  // const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <Form form={formikForm}>
      <SummaryContainer>
        <BoldTitle>
          <Name>
            {displayLarge ? displayLarge.libelle || '' : 'Médiathèque'}
          </Name>
          {
            !displayLarge ?
              <div>
                <IconButton
                  color="primary"
                  onClick={() => setOpenUpload(true)}
                  disabled={isSendingFile}
                >
                  <AddPhotoAlternateOutlined />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => onDownloadButtonClick()}
                >
                  <Download />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => onClickOpenSubMenu('filter')}
                >
                  <FilterList />
                </IconButton>
                {/* <IconButton
                color="primary"
                onClick={() => onClickOpenSubMenu('sort')}
              >
                <SwapVert />
              </IconButton> */}
                <IconButton
                  color="primary"
                  onClick={onClose}
                >
                  <Close />
                </IconButton>
              </div> :
              <div>
                <IconButton
                  color="primary"
                  onClick={() => setEdit(!edit)}
                >
                  {
                    edit ?
                      <EditOff />:
                      <Edit />
                  }
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => {setDisplayLarge(null); setEdit(false); openOutsideDocument(displayLarge)}}
                >
                  <OpenInNew />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={onFileDonwloadClick(displayLarge)}
                >
                  <Download />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => {setDisplayLarge(null); setEdit(false)}}
                >
                  <Close />
                </IconButton>
              </div>
          }
        </BoldTitle>
        {
          openSubMenu &&
            <div>
              {
                openSubMenu === 'filter' ?
                  <div>
                    <div>
                      <FormBoldTitle smaller>
                        Catégorie
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        value={formikForm.values.filters.category || ''}
                        renderValue={verifySelectFieldValue(formikForm.values.filters.category)}
                        name="filters.category"
                        displayEmpty
                        size="small"
                      >
                        <MenuItem value="">
                          Sélectionner
                        </MenuItem>
                        {
                          categoryList.map((value, index) => (
                            <MenuItem
                              key={`${value.code}-${index}`}
                              value={value.code}
                              disabled={isFetchingCategoryList}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                    </div>
                    <div>
                      <FormBoldTitle smaller>
                        Types
                      </FormBoldTitle>
                      <AutocompleteField
                        name="filters.types"
                        options={enumToSegmentedButtonOptions(MediathequeType).map((value) => value.value)}
                        getOptionLabel={(option) => enumToSegmentedButtonOptions(MediathequeType).find((value) => value.value === option)?.label || ''}
                        size="small"
                        multiple
                      />
                    </div>
                  </div> :
                  <div>
                    sort
                  </div>
              }
            </div>
        }
        <BoldSeparator />
        <ViewerContainer>
          {
            filteredDocumentList.length === 0 ?
              <NoMediaContainer>
                Aucun document
                <Button
                  variant="contained"
                  disabled={isSendingFile}
                  onClick={() => setOpenUpload(true)}
                >
                  Ajouter des éléments
                </Button>
              </NoMediaContainer> :
              !displayLarge ?
                <GridContainer>
                  {
                    filteredDocumentList.map((document, index) => (
                      <CaseLayoutMediaThumbnail
                        key={`${document.libelle}-${index}`}
                        selected={selected}
                        setSelected={setSelected}
                        document={document}
                        onDisplayLargeClick={() => openLargeDocument(document)}
                        onDisplayOutsideClick={() => openOutsideDocument(document)}
                      />
                    ))
                  }
                </GridContainer> :
                <CaseLayoutMediaFileDisplay
                  edit={edit}
                  document={displayLarge}
                />
          }
          {
            openUpload &&
              <ImportFilesModal
                handleClose={onCloseUpload}
                accept="application/pdf,image/*"
              />
          }
        </ViewerContainer>
      </SummaryContainer>
    </Form>
  )
}

export default CaseLayoutMedia
