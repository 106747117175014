/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseStatusListQuery,
  useGetCaseTypeActivityListQuery,
  useGetGestionnaireListQuery,
  useGetMetreurListQuery,
  useGetMissionableEntityListQuery,
  useGetTechnicianListQuery,
  usePatchCaseMissionMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import RateField from 'components/FieldWithInputAdornment/RateField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  MetreSimplifie,
  TravauxSimplifie,
  Franchise,
  CodeLabel,
  Mission,
  MissionDossierPartialUpdatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const missionSchema = Yup.object().shape<Shape<MissionDossierPartialUpdatePayload>>({
  entiteMissionnee: Yup.mixed<CodeLabel>(),
  dateReception: Yup.string(),
  montantOuverture: Yup.number().nullable(),
  codeEtat: Yup.mixed<CodeLabel>(),
  typeActivite: Yup.mixed<CodeLabel>(),
  thInitial: Yup.number().nullable(),
  gestionnaire: Yup.mixed<CodeLabel>(),
  missionUrgente: Yup.boolean(),
  franchise: Yup.object().shape<Shape<Franchise>>({
    dateRecuperation: Yup.string().nullable(),
    montant: Yup.number(),
    recuperee: Yup.boolean(),
    repreneur: Yup.string().nullable(),
  }),
  autreReference: Yup.string().nullable(),
  metreSimplifie: Yup.object().shape<Shape<MetreSimplifie>>({
    metreur: Yup.mixed<CodeLabel>().nullable(),
    commentaire: Yup.string().nullable(),
  }),
  travauxSimplifie: Yup.object().shape<Shape<TravauxSimplifie>>({
    technicien: Yup.mixed<CodeLabel>().nullable(),
    commentaire: Yup.string().nullable(),
  }),
})

type MissionForm = FormikContextType<MissionDossierPartialUpdatePayload>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

const DualContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Container = styled.div`
  width: 100%;
`

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
`

const CardContainer = styled(Card)`
  padding: 0px 10px 10px;
  margin-top: 10px;
`

const LargeFormTitle = styled(FormBoldTitle)`
  margin: 10px 0px -10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutMissionModalProps {
  handleClose: () => void;
  caseId: string;
  mission: Mission;
}

const CaseLayoutMissionModal: React.FC<CaseLayoutMissionModalProps> = ({
  handleClose,
  caseId,
  mission,
}) => {
  const [
    submitMission,
  ] = usePatchCaseMissionMutation()
  const {
    currentData: activityList = [],
    isFetching: isFetchingActivityList,
  } = useGetCaseTypeActivityListQuery()
  const {
    currentData: missionableEntityList = [],
    isFetching: isFetchingMissionableEntityList,
  } = useGetMissionableEntityListQuery()
  const {
    currentData: caseStatusList = [],
    isFetching: isFetchingCaseStatusList,
  } = useGetCaseStatusListQuery()
  const {
    currentData: gestionnaireList = [],
    isFetching: isFetchingGestionnaireList,
  } = useGetGestionnaireListQuery(caseId)
  const {
    currentData: metreurList = [],
    isFetching: isFetchingMetreurList,
  } = useGetMetreurListQuery(caseId)
  const {
    currentData: technicianList = [],
    isFetching: isFetchingTechnicianList,
  } = useGetTechnicianListQuery(caseId)

  const onSubmit = (values: MissionDossierPartialUpdatePayload, { setSubmitting }: FormikHelpers<MissionDossierPartialUpdatePayload>) => {
    submitMission({ caseId, data: values })
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const formikForm: MissionForm = useForm<MissionDossierPartialUpdatePayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        missionUrgente: false,
      },
      validationSchema: missionSchema,
    },
  )

  useEffect(() => {
    if (mission) {
      formikForm.setValues(mission)
    }
  }, [ mission ])

  const isLoading = useMemo(() => isFetchingActivityList || isFetchingMissionableEntityList || isFetchingCaseStatusList || isFetchingGestionnaireList, [
    isFetchingActivityList,
    isFetchingMissionableEntityList,
    isFetchingCaseStatusList,
    isFetchingGestionnaireList,
  ])

  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Modifier la mission
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        {
          isLoading ?
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer> :
            <DialogContentContainer>
              <CardContainer>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Entitée Missionnée
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      value={formikForm.values.entiteMissionnee?.code || ''}
                      renderValue={verifySelectFieldValue(formikForm.values.entiteMissionnee?.code)}
                      name="entiteMissionnee.code"
                      displayEmpty
                      size="small"
                    >
                      {
                        missionableEntityList.map((value, index) => (
                          <MenuItem
                            key={`${value.code}-${index}`}
                            value={value.code}
                            disabled={isFetchingMissionableEntityList}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      Date de réception
                    </FormBoldTitle>
                    <FormikDatePicker
                      name="dateReception"
                      size="small"
                    />
                  </Container>
                </DualContainer>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Montant d'ouverture
                    </FormBoldTitle>
                    <PriceField
                      name="montantOuverture"
                      size="small"
                      onChange={(e) => formikForm.setFieldValue('montantOuverture', e.target.value)}
                    />
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      Code état
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      value={formikForm.values.codeEtat?.code || ''}
                      renderValue={verifySelectFieldValue(formikForm.values.codeEtat?.code)}
                      name="codeEtat.code"
                      displayEmpty
                      size="small"
                      disabled={isFetchingCaseStatusList}
                    >
                      {
                        caseStatusList.map((value, index) => (
                          <MenuItem
                            key={`${value.code}-${index}`}
                            value={value.code}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </Container>
                </DualContainer>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Type d'activité
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      value={formikForm.values.typeActivite?.code || ''}
                      renderValue={verifySelectFieldValue(formikForm.values.typeActivite?.code)}
                      name="typeActivite.code"
                      displayEmpty
                      size="small"
                      disabled={isFetchingActivityList}
                    >
                      {
                        activityList.map((value, index) => (
                          <MenuItem
                            key={`${value.code}-${index}`}
                            value={value.code}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      TH initial
                    </FormBoldTitle>
                    <RateField
                      name="thInitial"
                      size="small"
                      onChange={(e) => formikForm.setFieldValue('thInitial', e.target.value)}
                    />
                  </Container>
                </DualContainer>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Gestionnaire
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      value={formikForm.values.gestionnaire?.code || ''}
                      renderValue={verifySelectFieldValue(formikForm.values.gestionnaire?.code)}
                      name="gestionnaire.code"
                      displayEmpty
                      size="small"
                      disabled={isFetchingGestionnaireList}
                    >
                      {
                        gestionnaireList.map((value, index) => (
                          <MenuItem
                            key={`${value.code}-${index}`}
                            value={value.code}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      Autre référence
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Autre référence"
                      name="autreReference"
                      size="small"
                    />
                  </Container>
                </DualContainer>
              </CardContainer>
              <CardContainer>
                <LargeFormTitle bigger>
                  Franchise
                </LargeFormTitle>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Montant
                    </FormBoldTitle>
                    <PriceField
                      name="franchise.montant"
                      size="small"
                      onChange={(e) => formikForm.setFieldValue('franchise.montant', e.target.value)}
                    />
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      A été récupérée ?
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      selectedOption={formikForm.values.franchise?.recuperee || false}
                      setSelectedOption={(v) => formikForm.setFieldValue('franchise.recuperee', v)}
                      smaller
                    />
                  </Container>
                </DualContainer>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Date de récupération
                    </FormBoldTitle>
                    <FormikDatePicker
                      name="franchise.dateRecuperation"
                      size="small"
                    />
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      Repreneur
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Repreneur"
                      name="franchise.repreneur"
                      size="small"
                    />
                  </Container>
                </DualContainer>
              </CardContainer>
              <CardContainer>
                <LargeFormTitle bigger>
                  Métré
                </LargeFormTitle>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Métreur
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      value={formikForm.values.metreSimplifie?.metreur?.code || ''}
                      renderValue={verifySelectFieldValue(formikForm.values.metreSimplifie?.metreur?.code)}
                      name="metreSimplifie.metreur.code"
                      displayEmpty
                      size="small"
                      disabled={isFetchingMetreurList}
                    >
                      {
                        metreurList.map((value, index) => (
                          <MenuItem
                            key={`${value.code}-${index}`}
                            value={value.code}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      Commentaire
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Votre message"
                      name="metreSimplifie.commentaire"
                      size="small"
                    />
                  </Container>
                </DualContainer>
              </CardContainer>
              <CardContainer>
                <LargeFormTitle bigger>
                  Travaux
                </LargeFormTitle>
                <DualContainer>
                  <Container>
                    <FormBoldTitle>
                      Technicien
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      value={formikForm.values.travauxSimplifie?.technicien?.code || ''}
                      renderValue={verifySelectFieldValue(formikForm.values.travauxSimplifie?.technicien?.code)}
                      name="travauxSimplifie.technicien.code"
                      displayEmpty
                      size="small"
                      disabled={isFetchingTechnicianList}
                    >
                      {
                        technicianList.map((value, index) => (
                          <MenuItem
                            key={`${value.code}-${index}`}
                            value={value.code}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </Container>
                  <Container>
                    <FormBoldTitle>
                      Commentaire
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Votre message"
                      name="travauxSimplifie.commentaire"
                      size="small"
                    />
                  </Container>
                </DualContainer>
              </CardContainer>
            </DialogContentContainer>
        }
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default CaseLayoutMissionModal
