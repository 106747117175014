/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetQualityListQuery,
  useGetSalutationListQuery,
  useGetTitleListQuery,
  usePatchCaseStakeholderMutation,
  usePostCaseStakeholderMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Feature } from 'geojson'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  CodeLabel,
  Adresse,
  AdresseRequest,
  Intervenant,
  CreateIntervenantRequest,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const stakeholderSchema = Yup.object().shape<Shape<CreateIntervenantRequest>>({
  nom: Yup.string().required('Le nom est obligatoire'),
  prenom: Yup.string().required('Le prénom est obligatoire'),
  civilite: Yup.mixed<CodeLabel>().nullable(),
  formulePolitesse: Yup.mixed<CodeLabel>().nullable(),
  email: Yup.string().email("L'email est invalide").nullable(),
  telephone: Yup.string().test(
    'personnal-phone',
    'Le portable est invalide',
    (item = '') => !item || (/^(06|07)/).test(item) && item.replaceAll(' ', '').length === 10,
  ).nullable(),
  adresse: Yup.object().shape<Shape<AdresseRequest>>({
    adresse1: Yup.string().required("L'adresse est obligatoire"),
    adresse2: Yup.string().nullable(),
    adresse3: Yup.string().nullable(),
    ville: Yup.string().required('La ville est obligatoire'),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
  }).required(),
  qualite: Yup.mixed<CodeLabel>().nullable(),
})

type StakeholderForm = FormikContextType<CreateIntervenantRequest>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

const DualContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Container = styled.div`
  width: 100%;
`

const AddressSeparator = styled.div`
  height: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutStakeholderModalProps {
  handleClose: () => void;
  caseId: string;
  stakeholder?: Intervenant;
}

const CaseLayoutStakeholderModal: React.FC<CaseLayoutStakeholderModalProps> = ({
  handleClose,
  caseId,
  stakeholder,
}) => {
  const {
    currentData: politeSentenceList = [],
    isFetching: isFetchingPoliteSentenceList,
  } = useGetSalutationListQuery()
  const {
    currentData: titleList = [],
    isFetching: isFetchingTitleList,
  } = useGetTitleListQuery()
  const {
    currentData: qualityList = [],
    isFetching: isFetchinQualityList,
  } = useGetQualityListQuery()
  const [
    submitNewStakeholder,
  ] = usePostCaseStakeholderMutation()
  const [
    submitEditStakeholder,
  ] = usePatchCaseStakeholderMutation()

  const onSubmit = (values: CreateIntervenantRequest, { setSubmitting }: FormikHelpers<CreateIntervenantRequest>) => {
    if (stakeholder) {
      submitEditStakeholder({ caseId, stakeholderId: stakeholder.id, data: values })
        .then((response) => {
          if (!isApiError(response)) {
            handleClose()
          }
        })
        .catch(console.error)
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      submitNewStakeholder({ caseId, data: { ...values }})
        .then((response) => {
          if (!isApiError(response)) {
            handleClose()
          }
        })
        .catch(console.error)
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const formikForm: StakeholderForm = useForm<CreateIntervenantRequest>(
    {
      onSubmit: onSubmit,
      initialValues: {
        nom: '',
        prenom: '',
        adresse: {
          adresse1: '',
          adresse2: '',
          adresse3: '',
          codePostal: '',
          ville: '',
        },
        email: '',
        telephone: '',
      },
      validationSchema: stakeholderSchema,
    },
  )

  useEffect(() => {
    if (stakeholder) {
      formikForm.setValues(stakeholder as CreateIntervenantRequest)
    }
  }, [ stakeholder ])

  const onAddressChange = (newAddress: Feature) => {
    formikForm.setValues({
      ...formikForm.values,
      adresse: {
        ...formikForm.values.adresse,
        adresse1: newAddress.properties?.name as string,
        codePostal: newAddress.properties?.postcode as string,
        ville: newAddress.properties?.city as string,
      },
    })
  }

  const addressErrorProps = useMemo(() => {
    const addressTouched = (formikForm.touched.adresse as unknown as Adresse)?.adresse1 || false
    const addressError = (formikForm.errors.adresse as unknown as Adresse)?.adresse1 || ''

    return ({
      error: Boolean(addressTouched && addressError),
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }, [ formikForm.errors.adresse, formikForm.touched.adresse ])

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          {`${stakeholder ? "Modifier l'" : 'Ajouter un '}intervenant`}
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        {
          <DialogContentContainer>
            <DualContainer>
              <Container>
                <FormBoldTitle>
                  Formule de politesse
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="formulePolitesse.code"
                  displayEmpty
                  size="small"
                  disabled={isFetchingPoliteSentenceList}
                  value={formikForm.values.formulePolitesse?.code || ''}
                  renderValue={verifySelectFieldValue(formikForm.values.formulePolitesse?.code)}
                >
                  <MenuItem value="">
                    Sélectionner
                  </MenuItem>
                  {
                    politeSentenceList.map((value, index) => (
                      <MenuItem
                        value={value.libelle || ''}
                        key={`${value.libelle}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </Container>
              <Container>
                <FormBoldTitle>
                  Civilité
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="civilite.code"
                  displayEmpty
                  size="small"
                  disabled={isFetchingTitleList}
                  value={formikForm.values.civilite?.code || ''}
                  renderValue={verifySelectFieldValue(formikForm.values.civilite?.code)}
                >
                  <MenuItem value="">
                    Sélectionner
                  </MenuItem>
                  {
                    titleList.map((value, index) => (
                      <MenuItem
                        value={value.libelle || ''}
                        key={`${value.libelle}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </Container>
              <Container>
                <FormBoldTitle>
                  Qualité
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="qualite.code"
                  displayEmpty
                  size="small"
                  disabled={isFetchinQualityList}
                  value={formikForm.values.qualite?.code || ''}
                  renderValue={verifySelectFieldValue(formikForm.values.qualite?.code)}
                >
                  <MenuItem value="">
                    Sélectionner
                  </MenuItem>
                  {
                    qualityList.map((value, index) => (
                      <MenuItem
                        value={value.libelle || ''}
                        key={`${value.libelle}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </Container>
            </DualContainer>
            <DualContainer>
              <Container>
                <FormBoldTitle required>
                  Prénom
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Prénom"
                  name="prenom"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle required>
                  Nom
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Nom"
                  name="nom"
                  size="small"
                  disabled={stakeholder?.nom}
                />
              </Container>
            </DualContainer>
            <DualContainer>
              <Container>
                <FormBoldTitle>
                  Téléphone
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Téléphone"
                  name="telephone"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle>
                  Email
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Email"
                  name="email"
                  size="small"
                />
              </Container>
            </DualContainer>
            <FormBoldTitle required>
              Adresse
            </FormBoldTitle>
            <AddressAutocomplete
              placeholder="Adresse"
              value={formikForm.values.adresse?.adresse1 || ''}
              onValueChange={(newValue: string) => formikForm.setFieldValue('adresse.adresse1', newValue)}
              onAddressChange={(newValue) => onAddressChange(newValue)}
              size="small"
              {...addressErrorProps}
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse2"
              size="small"
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse3"
              size="small"
            />
            <DualContainer>
              <Container>
                <FormBoldTitle required>
                  Code postal
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Code postal"
                  name="adresse.codePostal"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle required>
                  Ville
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ville"
                  name="adresse.ville"
                  size="small"
                />
              </Container>
            </DualContainer>
          </DialogContentContainer>
        }
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default CaseLayoutStakeholderModal
