/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import Table, { exportToXLSX } from 'components/Table/Table'
import {
  useGetDepartmentListQuery,
  useGetCaseInsuranceCompanyListQuery,
  useGetCaseListQuery,
  useGetDisasterListQuery,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import {
  caseTabs,
  CaseTabType,
  getCaseStatusColor,
} from 'helpers/caseUtils'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import { getCaseListPeriod } from 'store/slices/caseListFilterSlice'
import { addCase } from 'store/slices/caseListSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
} from '@mui/material'
import {
  Search,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import { TextField } from 'formik-mui'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import RoundedChip from 'components/RoundedChip/RoundedChip'
import CheckableSortingButton from 'components/CheckableSortingButton/CheckableSortingButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import TableFilterList from 'components/Table/TableFilterList'
import CaseListFilters from './DashboardAllCaseListComponents/DashboardAllCaseListFilters'
import CaseListMobileCard from './DashboardAllCaseListComponents/DashboardAllCaseListMobileCard'
import DashboardAllCaseDateFilter from './DashboardAllCaseListComponents/DashboardAllCaseDateFilter'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
} from 'formik'
import type { ColumnHeader } from 'components/Table/Table'
import {
  type RechercheDossierCreatePayload,
  type CodeLabel,
  type DossierRecherche,
  ColonneRechercheDossier,
  TypeOrder,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const myCasesSchema = Yup.mixed<RechercheDossierCreatePayload>().required()

type CaseListForm = FormikContextType<RechercheDossierCreatePayload>

interface OpenedMenu {
  filter: boolean;
  sort: boolean;
}

/* Internal variable declarations ------------------------------------------- */
const RESULT_LIMIT = 10

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 85% 14%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgrey};
`

const CustomChip = styled(RoundedChip)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;

  .status-chip {
    font-size: .8rem;
    width: 100%;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
`

const SearchExportContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 430px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DashboardAllCaseListPageProps {}

const DashboardAllCaseListPage: React.FC<DashboardAllCaseListPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const period = useAppSelector(getCaseListPeriod)
  const [ openedMenu, setOpenedMenu ] = useState<OpenedMenu>({ filter: false, sort: false })
  const [ statusFilters, setStatusFilters ] = useState<{status: CodeLabel; amount: number | null}[]>([])
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ order, setOrder ] = useState<TypeOrder>(TypeOrder.Desc)
  const [ orderBy, setOrderBy ] = useState<ColonneRechercheDossier>(ColonneRechercheDossier.DateMissionnement)

  const {
    currentData: companyList = [],
    isFetching: isFetchingCompanyList,
  } = useGetCaseInsuranceCompanyListQuery()
  const {
    currentData: departmentList = [],
    isFetching: isFetchingDepartmentList,
  } = useGetDepartmentListQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterListQuery()

  const formikForm: CaseListForm = useForm<RechercheDossierCreatePayload>(
    {
      initialValues: {
        limite: RESULT_LIMIT,
        compagnieAssurance: [],
        dateDebut: period,
        dateFin: new Date().toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        departement: '',
        indexDebut: 0,
        natureSinistre: [],
        rechercheLibre: '',
        statut: [],
      },
      validationSchema: myCasesSchema,
    },
  )

  const parseFilters = (params: RechercheDossierCreatePayload) => {
    const result = structuredClone(params)

    for (const key in params) {
      const typedKey = key as keyof RechercheDossierCreatePayload
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[typedKey] = params[typedKey] === null ? '' : params[typedKey]
    }

    return result
  }

  const {
    currentData: caseResultList,
    isFetching: isFetchingCaseResultList,
  } = useGetCaseListQuery(parseFilters(formikForm.values))

  const saveLastPath = (caseId: string) => {
    dispatch(setRouterLastPath(location.pathname))
    dispatch(addCase({ caseId, tab: caseTabs.find((v) => v.type === CaseTabType.CaseHistory)?.path || '', name: caseResultList?.resultats?.find((c) => c.id === caseId)?.assure || '' }))
  }

  const handleNavigate = (caseId: string) => {
    saveLastPath(caseId)
    navigate(`/dossiers/${caseId}/${caseTabs.find((v) => v.type === CaseTabType.CaseHistory)?.path}`)
  }

  useEffect(() => {
    if (caseResultList?.totalResultats !== undefined && caseResultList.totalResultats !== totalRows) {
      setTotalRows(caseResultList.totalResultats)
    }
    if (caseResultList?.nombreResultatParStatut) {
      setStatusFilters(
        caseResultList?.nombreResultatParStatut?.map(({ nombreResultat, statutComposeDossier }) => ({
          status: statutComposeDossier || { code: '', libelle: '' },
          amount: nombreResultat || 0,
        })),
      )
    }
  }, [ caseResultList ])

  const handleFilters = (fieldName: ColonneRechercheDossier, value: string) => {
    let filters = structuredClone(formikForm.values.filtresValeur) || []

    if (filters.some((v) => v.filtre === fieldName)) {
      if (value) {
        filters = filters.map((v) => v.filtre === fieldName ? { ...v, valeur: value } : v)
      } else {
        filters = filters.filter((v) => v.filtre !== fieldName)
      }
    } else {
      filters.push({ filtre: fieldName, valeur: value })
    }

    formikForm.setFieldValue('filtresValeur', filters)
  }

  const cols: ColumnHeader<DossierRecherche>[] = [
    {
      id: 'assure',
      label: 'Assuré',
      sortId: ColonneRechercheDossier.Assure,
      isFiltered: formikForm.values.filtresValeur?.some((v) => v.filtre === ColonneRechercheDossier.Assure),
      filterComponent: <TableFilterList
        checkedItems={[ formikForm.values.filtresValeur?.find((v) => v.filtre === ColonneRechercheDossier.Assure)?.valeur || '' ]}
        items={[]}
        onItemsChange={(value) => handleFilters(ColonneRechercheDossier.Assure, value[0])}
        createNew
      />,
    },
    {
      id: 'id',
      label: 'Ref mandant',
      sortId: ColonneRechercheDossier.Id,
      isFiltered: formikForm.values.filtresValeur?.some((v) => v.filtre === ColonneRechercheDossier.Id),
      filterComponent: <TableFilterList
        checkedItems={[ formikForm.values.filtresValeur?.find((v) => v.filtre === ColonneRechercheDossier.Id)?.valeur || '' ]}
        items={[]}
        onItemsChange={(value) => handleFilters(ColonneRechercheDossier.Id, value[0])}
        createNew
      />,
      render: (companyRef: string, row) => (
        <Link
          href={`/dossiers/${(row as DossierRecherche).id}/${caseTabs.find((v) => v.type === CaseTabType.CaseHistory)?.path}`}
          onClick={(e) => {e.stopPropagation(); saveLastPath((row as DossierRecherche).id)}}
        >
          {companyRef}
        </Link>
      ),
    },
    {
      id: 'dateMissionnement',
      label: 'Date missionnement',
      sortId: ColonneRechercheDossier.DateMissionnement,
      isFiltered: formikForm.values.filtresValeur?.some((v) => v.filtre === ColonneRechercheDossier.DateMissionnement),
      filterComponent: <DashboardAllCaseDateFilter
        value={formikForm.values.filtresValeur?.find((v) => v.filtre === ColonneRechercheDossier.DateMissionnement)?.valeur || ''}
        onChange={(value) => handleFilters(ColonneRechercheDossier.DateMissionnement, value)}
      />,
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'referenceCompagnie',
      label: 'Ref sinistre',
      sortId: ColonneRechercheDossier.ReferenceCompagnie,
      isFiltered: formikForm.values.filtresValeur?.some((v) => v.filtre === ColonneRechercheDossier.ReferenceCompagnie),
      filterComponent: <TableFilterList
        checkedItems={[ formikForm.values.filtresValeur?.find((v) => v.filtre === ColonneRechercheDossier.ReferenceCompagnie)?.valeur || '' ]}
        items={[]}
        onItemsChange={(value) => handleFilters(ColonneRechercheDossier.ReferenceCompagnie, value[0])}
        createNew
      />,
    },
    {
      id: 'ville',
      label: 'Ville',
      sortId: ColonneRechercheDossier.Ville,
      isFiltered: formikForm.values.filtresValeur?.some((v) => v.filtre === ColonneRechercheDossier.Ville),
      filterComponent: <TableFilterList
        checkedItems={[ formikForm.values.filtresValeur?.find((v) => v.filtre === ColonneRechercheDossier.Ville)?.valeur || '' ]}
        items={[]}
        onItemsChange={(value) => handleFilters(ColonneRechercheDossier.Ville, value[0])}
        createNew
      />,
    },
    {
      id: 'natureSinistre.libelle',
      label: 'Type de sinistre',
      sortId: ColonneRechercheDossier.NatureSinistreLibelle,
      isFiltered: (formikForm.values.natureSinistre?.length || 0) > 0,
      filterComponent: <TableFilterList
        checkedItems={formikForm.values.natureSinistre || []}
        items={disasterNatureList}
        onItemsChange={(value) => formikForm.setFieldValue('natureSinistre', value)}
        multiple
      />,
    },
    {
      id: 'statutComposeDossier',
      label: 'Statut',
      sortId: ColonneRechercheDossier.StatutComposeDossierLibelle,
      isFiltered: (formikForm.values.statut?.length || 0) > 0,
      filterComponent: <TableFilterList
        checkedItems={formikForm.values.statut || []}
        items={statusFilters.map((v) => v.status)}
        onItemsChange={(value) => formikForm.setFieldValue('statut', value)}
        multiple
      />,
      render: (status: CodeLabel) => {
        return (
          <StatusChipContainer>
            <ColoredSquareChip
              color={getCaseStatusColor(status.code)}
              className="status-chip"
            >
              {status.libelle}
            </ColoredSquareChip>
          </StatusChipContainer>
        )
      },
      renderForExport: (status: CodeLabel) => status.libelle || '',
    },
  ]

  const exportDataToXLSX = () => {
    exportToXLSX(cols.filter((col) => !col.removeFromExport), caseResultList?.resultats || [], `vos dossiers_${new Date().toISOString().replace(/[-T:]/g, '').slice(0, 14)}`)
  }

  const onStatusFilterClick = (status: string) => {
    if (formikForm.values.statut?.includes(status)) {
      formikForm.setFieldValue('statut', formikForm.values.statut.filter((stat) => stat !== status))
    } else {
      formikForm.setFieldValue('statut', [ ...formikForm.values.statut || [], status ])
    }
  }

  const handleSort = (property: ColonneRechercheDossier) => () => {
    const isAsc = orderBy === property && order === TypeOrder.Asc
    setOrder(isAsc ? TypeOrder.Desc : TypeOrder.Asc)
    setOrderBy(property)
    formikForm.setValues({ ...formikForm.values, ordre: { type: isAsc ? TypeOrder.Desc : TypeOrder.Asc, filtre: property }})
    setOpenedMenu({ ...openedMenu, sort: false })
  }

  useEffect(() => {
    formikForm.setFieldValue('ordre.type', order)
  }, [ order ])

  useEffect(() => {
    formikForm.setFieldValue('ordre.filtre', orderBy)
  }, [ orderBy ])

  return (
    <Form form={formikForm}>
      <Card>
        <CardContent>
          <LargeTitle>
            Tous les dossiers
            <SearchExportContainer>
              <Field
                component={TextField}
                name="rechercheLibre"
                placeholder="Rechercher dans le tableau"
                size="small"
                InputProps={
                  {
                    endAdornment: (
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    ),
                  }
                }
              />
              <LongButton
                variant="contained"
                onClick={exportDataToXLSX}
              >
                Exporter
              </LongButton>
            </SearchExportContainer>
          </LargeTitle>
          <FilterDesktopContainer>
            <CaseListFilters
              companyList={companyList}
              isFetchingCompanyList={isFetchingCompanyList}
              disasterNatureList={disasterNatureList}
              isFetchingDisasterNatureList={isFetchingDisasterNatureList}
              departmentList={departmentList}
              isFetchingDepartmentList={isFetchingDepartmentList}
            />
          </FilterDesktopContainer>
          <FilterMobileContainer>
            <BoldSeparator />
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, filter: !openedMenu.filter })}>
              Filtres
              <DropDownArrow open={openedMenu.filter} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.filter}
              timeout="auto"
              unmountOnExit
            >
              <CaseListFilters
                companyList={companyList}
                isFetchingCompanyList={isFetchingCompanyList}
                disasterNatureList={disasterNatureList}
                isFetchingDisasterNatureList={isFetchingDisasterNatureList}
                departmentList={departmentList}
                isFetchingDepartmentList={isFetchingDepartmentList}
              />
            </Collapse>
            <BoldSeparator />
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, sort: !openedMenu.sort })}>
              Trier par
              <DropDownArrow open={openedMenu.sort} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.sort}
              timeout="auto"
              unmountOnExit
            >
              {
                cols.map((col) => (
                  <CheckableSortingButton
                    key={col.id}
                    checked={orderBy === (col.sortId as ColonneRechercheDossier || col.id)}
                    label={col.label}
                    onChange={handleSort(col.sortId as ColonneRechercheDossier || col.id)}
                    order={order === TypeOrder.Asc ? 'asc' : 'desc'}
                  />
                ))
              }
            </Collapse>
            <BoldSeparator />
          </FilterMobileContainer>
          <FormBoldTitle>
            Filtrer par statut
          </FormBoldTitle>
          <ScrollableFiltersContainer>
            {
              statusFilters.map((filter) => (
                <CustomChip
                  variant={formikForm.values.statut?.includes(filter.status.code) ? 'contained' : 'outlined'}
                  key={filter.status.code}
                  onClick={() => onStatusFilterClick(filter.status.code)}
                >
                  {filter.status.libelle}
                  <b>
                    {filter.amount}
                  </b>
                </CustomChip>
              ))
            }
          </ScrollableFiltersContainer>
        </CardContent>
      </Card>
      <LoadingOverlay isLoading={isFetchingCaseResultList}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={[ ...caseResultList?.resultats || [] ]}
              colHeaders={cols}
              setRows={() => null}
              onRowClick={(row) => handleNavigate((row).id)}
              limit={RESULT_LIMIT}
              removeFilter={(sortId) => handleFilters(sortId as ColonneRechercheDossier, '')}
              pagination={
                {
                  setServerLimit: (limit) => formikForm.setFieldValue('limite', limit),
                  setServerStartIndex: (startIndex) => formikForm.setFieldValue('indexDebut', startIndex),
                  totalRows,
                }
              }
              sorting={
                {
                  setOrder: (value) => setOrder(value === 'asc' ? TypeOrder.Asc : TypeOrder.Desc),
                  setOrderBy: (value) => setOrderBy(value as ColonneRechercheDossier),
                }
              }
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          {
            [ ...caseResultList?.resultats || [] ].map((myCase, index) => (
              <CaseListMobileCard
                key={`${myCase.id}-${index}`}
                myCase={myCase}
                handleNavigate={() => handleNavigate(myCase.id)}
              />
            ))
          }
        </MobileCardContainer>
      </LoadingOverlay>
    </Form>
  )
}

export default DashboardAllCaseListPage
