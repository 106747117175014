/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseHistoryWorkflowJalon from './CaseHistoryWorkflowJalon'
import CaseHistoryWorkflowArrow from './CaseHistoryWorkflowArrow'

/* Type imports ------------------------------------------------------------- */
import type { WorkflowEntreprise } from 'API/__generated__/Api'
import {
  WorkflowEtat,
  WorkflowJalon,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface ContainerProps {
  count: number;
}

const CardContainer = styled(Card)<ContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.count}, 1fr)`};
  margin-bottom: 10px;
  width: 100%;
`

const ArrowConatiner = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.count}, 1fr)`};
`

const Wrapper = styled.div`
  min-width: fit-content;
  width: 100%;
`

const Container = styled.div`
  overflow-x: scroll;
  margin-bottom: 20px;
`

interface JalonContainerProps {
  last: boolean;
}

const JalonContainer = styled.div<JalonContainerProps>`
  border-right: ${(props) => !props.last ? `2px solid ${props.theme.colors.grey}` : ''};
  padding: 10px 15px;
  color: ${(props) => props.theme.palette.secondary.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 150px;
  font-size: .9rem;
`

/* Component declaration ---------------------------------------------------- */
interface CaseHistoryWorkflowProps {
  workflow: WorkflowEntreprise;
}

const CaseHistoryWorkflow: React.FC<CaseHistoryWorkflowProps> = ({ workflow }) => {
  const { caseId = '' } = useParams<{caseId: string}>()

  const findJalon = (jalon: WorkflowJalon) => {
    if (!workflow) return
    switch (jalon) {
      case WorkflowJalon.Missionnement:
        if (!workflow.jalonMissionnement) return
        return (
          {
            jalon: workflow.jalonMissionnement,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonMissionnement.type.libelle}
                state={workflow.jalonMissionnement?.etat}
                date={workflow.jalonCreation?.dateCreation}
              />
            ),
          }
        )
      case WorkflowJalon.DevisHorsPriseEnCharge:
        if (!workflow.jalonDevisHorsPriseEnCharge) return
        return (
          {
            jalon: workflow.jalonDevisHorsPriseEnCharge,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonDevisHorsPriseEnCharge.type.libelle}
                state={workflow.jalonDevisHorsPriseEnCharge?.etat}
                date={workflow.jalonDevisHorsPriseEnCharge?.dateCreation}
              />
            ),
          }
        )
      case WorkflowJalon.Intervention:
        if (!workflow.jalonIntervention) return
        return (
          {
            jalon: workflow.jalonIntervention,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonIntervention.type.libelle}
                state={workflow.jalonIntervention?.etat}
                date={workflow.jalonIntervention?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.PostIntervention:
        if (!workflow.jalonPostIntervention) return
        return (
          {
            jalon: workflow.jalonPostIntervention,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonPostIntervention.type.libelle}
                state={workflow.jalonPostIntervention?.etat}
                date={workflow.jalonPostIntervention?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.Contact:
        if (!workflow.jalonPriseDeContact) return
        return (
          {
            jalon: workflow.jalonPriseDeContact,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonPriseDeContact.type.libelle}
                state={workflow.jalonPriseDeContact?.etat}
                date={workflow.jalonPriseDeContact?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.RDVMetre:
        if (!workflow.jalonRDVMetre) return
        return (
          {
            jalon: workflow.jalonRDVMetre,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonRDVMetre.type.libelle}
                state={workflow.jalonRDVMetre?.etat}
                date={workflow.jalonRDVMetre?.dateRendezVous}
              />
            ),
          }
        )
      case WorkflowJalon.PostRdvMetre:
        if (!workflow.jalonPostRdvMetre) return
        return (
          {
            jalon: workflow.jalonPostRdvMetre,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonPostRdvMetre.type.libelle}
                state={workflow.jalonPostRdvMetre?.etat}
                date={workflow.jalonPostRdvMetre?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.Devis:
        if (!workflow.jalonDevis) return
        return (
          {
            jalon: workflow.jalonDevis,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonDevis.type.libelle}
                state={workflow.jalonDevis?.etat}
                date={workflow.jalonDevis?.dateAffichee}
              />
            ),
          }
        )
      case WorkflowJalon.RDVTravaux:
        if (!workflow.jalonRDVTravaux) return
        return (
          {
            jalon: workflow.jalonRDVTravaux,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonRDVTravaux.type.libelle}
                state={workflow.jalonRDVTravaux?.etat}
                date={workflow.jalonRDVTravaux?.rendezVousTravaux[0]?.dateDebut}
              />
            ),
          }
        )
      case WorkflowJalon.FinDeTravaux:
        if (!workflow.jalonFinDeTravaux) return
        return (
          {
            jalon: workflow.jalonFinDeTravaux,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonFinDeTravaux.type.libelle}
                state={workflow.jalonFinDeTravaux?.etat}
                date={workflow.jalonFinDeTravaux?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.FinDeTravauxRDF:
        if (!workflow.jalonFinDeTravauxRDF) return
        return (
          {
            jalon: workflow.jalonFinDeTravauxRDF,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonFinDeTravauxRDF.type.libelle}
                state={workflow.jalonFinDeTravauxRDF?.etat}
                date={workflow.jalonFinDeTravauxRDF?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.CompteRenduVisite:
        if (!workflow.jalonCompteRendu) return
        return (
          {
            jalon: workflow.jalonCompteRendu,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonCompteRendu.type.libelle}
                state={workflow.jalonCompteRendu?.etat}
                date={workflow.jalonCompteRendu?.date}
              />
            ),
          }
        )
      case WorkflowJalon.CompteRenduRDF:
        if (!workflow.jalonCompteRendu) return
        return (
          {
            jalon: workflow.jalonCompteRendu,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonCompteRendu.type.libelle}
                state={workflow.jalonCompteRendu?.etat}
                date={workflow.jalonCompteRendu?.date}
              />
            ),
          }
        )
      case WorkflowJalon.Facture:
        if (!workflow.jalonFacture) return
        return (
          {
            jalon: workflow.jalonFacture,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonFacture.type.libelle}
                state={workflow.jalonFacture?.etat}
                date={workflow.jalonFacture?.dateAffichee}
              />
            ),
          }
        )
      case WorkflowJalon.DossierTermine:
        if (!workflow.jalonDossierTermine) return
        return (
          {
            jalon: workflow.jalonDossierTermine,
            render: (
              <CaseHistoryWorkflowJalon
                label={workflow.jalonDossierTermine.type.libelle}
                state={workflow.jalonDossierTermine?.etat}
                date={workflow.jalonDossierTermine?.date}
              />
            ),
          }
        )
      default:
        break
    }
  }

  const order = useMemo(() => workflow.order.filter((val) => val !== WorkflowJalon.Anomalies), [ workflow ])

  return (
    <Container>
      <Wrapper>
        <CardContainer count={order.length}>
          {
            order.map((order, index, { length }) => (
              <JalonContainer
                key={`jalon-${caseId}-${order}`}
                last={index === length -1}
                id={`jalon-${caseId}-${order}`}
              >
                {findJalon(order)?.render}
              </JalonContainer>
            ))
          }
        </CardContainer>
        <ArrowConatiner count={order.length}>
          {
            order.map((order) => (
              <CaseHistoryWorkflowArrow
                id={`jalon-${caseId}-${order}`}
                key={`jalon-${caseId}-${order}`}
                state={findJalon(order)?.jalon.etat || WorkflowEtat.EnAttente}
              />
            ))
          }
        </ArrowConatiner>
      </Wrapper>
    </Container>
  )
}

export default CaseHistoryWorkflow
