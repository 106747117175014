/* Framework imports -------------------------------------------------------- */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { frFR } from '@mui/x-date-pickers/locales'
import { fr as Datefr } from 'date-fns/locale'
import {
  L10n,
  setCulture,
  registerLicense,
  validateLicense,
} from '@syncfusion/ej2/base'
import fr from '@syncfusion/ej2-locale/src/fr.json'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import {
  persistor,
  store,
} from 'store/store'
import { readConfig } from 'helpers/readConfig'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

/* Component imports -------------------------------------------------------- */
import App from './App/App'

/* Styling imports ---------------------------------------------------------- */
import '@syncfusion/ej2/material.css'
import 'react-toastify/dist/ReactToastify.css'
import 'leaflet/dist/leaflet.css'
import { emotionTheme } from './theme/emotionTheme'
import './theme/index.css'

/* React startup ------------------------------------------------------------ */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (process.env.REACT_APP_LOGS === 'false') {
  console.log = () => {}
}

// Register syncfusion and add components translations
L10n.load({ 'fr':
  {
    ...fr.fr,
    documenteditorcontainer: {
      ...fr.fr.documenteditorcontainer,
      'of': 'sur',
      'Comments': 'Commentaires',
      'Undo': 'Annuler',
      'Redo': 'Rétablir',
      'Table': 'Tableau',
      'Find': 'Rechercher',
      'Paste': 'Coller',
      'Copy': 'Copier',
    },
    'image-editor': {
      ...fr.fr['image-editor'],
      'Undo': 'Annuler',
      'Redo': 'Rétablir',
      'Blur': 'Flou',
      'Sharpen': 'Netteté',
      'RotateLeft': 'Rotation à gauche',
      'RotateRight': 'Rotation à droite',
      'OK': 'Ok',
      'DlgOk': 'Ok',
      'FillColor': 'Couleur de remplissage',
      'FontStyle': 'Style de police',
      'Pan': 'Déplacer',
      'Move': 'Déplacer',
      'Custom': 'Personnalisé',
      'Line': 'Ligne',
      'Path': 'Tracé',
      'Bold': 'Gras',
      'XSmall': 'Très petit',
      'XLarge': 'Très grand',
      'Remove': 'Supprimer',
      'Start': 'Début',
      'CropSelection': 'Sélection du rognage',
      'W': 'L',
      'DragText': 'Glisser',
      'DropText': 'Déposer une image ou',
      'BrowseText': 'cliquer ici...',
    },
  }})
setCulture('fr')
validateLicense(process.env.REACT_APP_SYNCFUSION_KEY)
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY || '')

readConfig().then(() => {
  root.render(
    <React.StrictMode>
      <Redux.Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <ThemeProvider theme={emotionTheme}>
              <LocalizationProvider
                adapterLocale={Datefr}
                dateAdapter={AdapterDateFns}
                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <CssBaseline />
                <App />
              </LocalizationProvider>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Redux.Provider>
      <ToastContainer
        position="top-center"
        draggable
        theme="colored"
      />
    </React.StrictMode>,
  )
}).catch((error) => console.error('CONFIG LOAD ERROR : ', error))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
