/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import {
  Add,
  EditOutlined,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import CaseLayoutStakeholderModal from './CaseLayoutStakeholdersAddEditModal'
import CaseLayoutInsuredModal from './CaseLayoutInsuredEditModal'

/* Type imports ------------------------------------------------------------- */
import { IconButtonSize } from 'components/IconButtons/CustomIconButton/CustomIconButtonContainer'
import type {
  Intervenant,
  Personne,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px !important;
`

const Flex = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const BorderedContainer = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.grey}`};
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
`

const SmallContainer = styled.div`
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
`

const StakeholderNameAndTitle = styled.div`
  display: flex;
  flex-direction: column;
`

const StakeholderTitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`

const StakeholderName = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryStakeholdersProps {
  insured: Personne;
  stakeholders: Intervenant[];
  caseId: string;
}

const CaseLayoutSummaryStakeholders: React.FC<CaseLayoutSummaryStakeholdersProps> = ({
  insured,
  stakeholders,
  caseId,
}) => {
  const [ expanded, setExpanded ] = useState<boolean>(true)
  const [ open, setOpen ] = useState<boolean | Intervenant>(false)
  const [ openInsured, setOpenInsured ] = useState<boolean>(false)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <BoldTitle
        onClick={handleExpandClick}
        isDropdown
      >
        <Flex>
          {`Intervenants (${stakeholders.length + 1})`}
          <CustomIconButton
            Icon={Add}
            onClick={(e) => {e.stopPropagation(); setOpen(true)}}
            color="secondary"
            label="Ajouter un intervenant"
            customsize={IconButtonSize.small}
          />
        </Flex>
        <DropDownArrow open={expanded} />
      </BoldTitle>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <BorderedContainer>
          <StakeholderName>
            <StakeholderNameAndTitle>
              <StakeholderTitle>
                Assuré
              </StakeholderTitle>
              <b>
                {[ insured.prenom, insured.nom ].join(' ')}
              </b>
            </StakeholderNameAndTitle>
            <CustomIconButton
              Icon={EditOutlined}
              onClick={(e) => {e.stopPropagation(); setOpenInsured(true)}}
              color="secondary"
              label={`Modifier ${[ insured.prenom, insured.nom ].join(' ')}`}
              customsize={IconButtonSize.small}
            />
          </StakeholderName>
          <SmallContainer>
            {
              insured.telephonePortable &&
                <div>
                  {'Portable : '}
                  <a
                    href={`tel:${insured.telephonePortable}`}
                    target="_self"
                  >
                    {insured.telephonePortable}
                  </a>
                </div>
            }
            {
              insured.telephoneFixe &&
                <div>
                  {'Fixe : '}
                  <a
                    href={`tel:${insured.telephoneFixe}`}
                    target="_self"
                  >
                    {insured.telephoneFixe}
                  </a>
                </div>
            }
            {
              insured.email &&
                <div>
                  {'Email : '}
                  <a
                    href={`mailto:${insured.email}`}
                    target="_self"
                  >
                    {insured.email}
                  </a>
                </div>
            }
          </SmallContainer>
        </BorderedContainer>
        {
          stakeholders.map((value, index) => (
            <BorderedContainer key={`${value.nom}-${index}`}>
              <StakeholderName>
                <StakeholderNameAndTitle>
                  <StakeholderTitle>
                    {value.qualite?.libelle}
                  </StakeholderTitle>
                  <b>
                    {[ value.prenom, value.nom ].join(' ')}
                  </b>
                </StakeholderNameAndTitle>
                <CustomIconButton
                  Icon={EditOutlined}
                  onClick={(e) => {e.stopPropagation(); setOpen(value)}}
                  color="secondary"
                  label={`Modifier ${[ value.prenom, value.nom ].join(' ')}`}
                  customsize={IconButtonSize.small}
                />
              </StakeholderName>
              <SmallContainer>
                {
                  value.telephone &&
                    <div>
                      {'Tel : '}
                      <a
                        href={`tel:${value.telephone}`}
                        target="_self"
                      >
                        {value.telephone}
                      </a>
                    </div>
                }
                {
                  value.email &&
                    <div>
                      {'Email : '}
                      <a
                        href={`mailto:${value.email}`}
                        target="_self"
                      >
                        {value.email}
                      </a>
                    </div>
                }
              </SmallContainer>
            </BorderedContainer>
          ))
        }
      </Collapse>
      {
        open &&
          <CaseLayoutStakeholderModal
            caseId={caseId}
            stakeholder={typeof open !== 'boolean' ? open : undefined}
            handleClose={() => setOpen(false)}
          />
      }
      {
        openInsured &&
          <CaseLayoutInsuredModal
            caseId={caseId}
            insured={insured}
            handleClose={() => setOpenInsured(false)}
          />
      }
    </div>
  )
}

export default CaseLayoutSummaryStakeholders
