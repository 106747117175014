/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'

/* Type imports ------------------------------------------------------------- */
import type { Mandant } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px !important;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryMandatorsProps {
  mandatorList: Mandant[];
}

const CaseLayoutSummaryMandators: React.FC<CaseLayoutSummaryMandatorsProps> = ({ mandatorList }) => {
  const [ expanded, setExpanded ] = useState<boolean>(true)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  return (
    <>
      <BoldTitle
        onClick={handleExpandClick}
        isDropdown
      >
        Mandants
        <DropDownArrow open={expanded} />
      </BoldTitle>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {
          mandatorList.map((value, index) => (
            <div key={`${value.id}-${index}`}>
              <b>
                {value.nom}
              </b>
              {` - Ref : ${value.reference || ''}` }
            </div>
          ))
        }
      </Collapse>
    </>
  )
}

export default CaseLayoutSummaryMandators
