/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { hasAddress } from 'helpers/mapHelpers'

/* Component imports -------------------------------------------------------- */
import {
  MapContainer,
  TileLayer,
  ZoomControl,
} from 'react-leaflet'
import Button from '@mui/material/Button'
import PlanningMapMarker from '../PlanningMapMarker/PlanningMapMarker'

/* Type imports ------------------------------------------------------------- */
import type { Map } from 'leaflet'
import type { TileLayerProps } from 'react-leaflet'
import type { Planning } from 'types/Planning'

/* Internal variable declarations ------------------------------------------- */
const flat: TileLayerProps = {
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
}

const satellite: TileLayerProps = {
  url: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  subdomains: [
    'mt0',
    'mt1',
    'mt2',
    'mt3',
  ],
}

/* Styled components -------------------------------------------------------- */
const PlanningMapContainer = styled.div`
  width: 100%;
  height: calc(100vh - 270px);
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    height: calc(100vh - 70px);
  }

  .leaflet, .leaflet-container {
    height: 100%;
    width: 100%;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 1rem;
    line-height: normal;
  }

  .leaflet-div-icon {
    background-color: transparent !important;
    border: none !important;
  }

  .leaflet-div-icon b {
    position: absolute;
    top: -45px;
    left: -5px;
  }

  .pin-container {
    width: 40px;
    margin-left: -20px;
    margin-top: -55px;

    b {
      z-index: 2;
      color: ${(props) => props.theme.palette.secondary.main};
    }

    svg {
      filter: drop-shadow(0px 0px 5px #000000);
    }
  }
`

const LeafletControl = styled.div`
  margin: 10px;
  gap: 10px;
  display: flex;
  // remove this to display the map type buttons
  /* display: none; */
`

/* Component declaration ---------------------------------------------------- */
interface PlanningMapProps {
  events: Planning[];
  mapRef: Map | null;
  setMapRef: React.Dispatch<React.SetStateAction<Map | null>>;
  selectedEvent: string;
  setSelectedEvent: (event: Planning) => void;
}

const PlanningMap: React.FC<PlanningMapProps> = ({
  events,
  mapRef,
  setMapRef,
  selectedEvent,
  setSelectedEvent,
}) => {
  const [ tileProps, setTileProps ] = useState<TileLayerProps>(flat)

  const TileLayerComponent = () => {
    return <TileLayer {...tileProps} />
  }

  return (
    <PlanningMapContainer>
      <MapContainer
        zoom={5}
        center={[ 47.078920, 2.801706 ]}
        zoomControl={false}
        ref={setMapRef}
      >
        <TileLayerComponent />
        <LeafletControl className="leaflet-control">
          <Button
            variant={tileProps.url === flat.url ? 'contained' : 'outlined'}
            onClick={() => setTileProps(flat)}
          >
            Plan
          </Button>
          <Button
            variant={tileProps.url === satellite.url ? 'contained' : 'outlined'}
            onClick={() => setTileProps(satellite)}
          >
            Satellite
          </Button>
        </LeafletControl>
        {
          events.filter((event) => hasAddress(event.adresse)).map((event, index) => (
            <PlanningMapMarker
              key={`${event.id}-${index}`}
              event={event}
              eventNumber={index + 1}
              mapRef={mapRef}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
          ))
        }
        <ZoomControl position="bottomright" />
      </MapContainer>
    </PlanningMapContainer>
  )
}

export default PlanningMap
