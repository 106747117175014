/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useGetCaseDocumentListQuery } from 'store/api'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getLayout,
  setOpenMedia,
  setOpenSummary,
} from 'store/slices/layoutSlice'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import {
  AssignmentTurnedIn,
  CalendarMonth,
  Email,
  InfoRounded,
  InsertDriveFile,
  MoreHoriz,
  Sms,
  StickyNote2,
} from '@mui/icons-material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import CaseLayoutSummary from './CaseLayoutComponents/CaseLayoutSummary'
import CaseLayoutNavTabs from './CaseLayoutComponents/CaseLayoutNavTabs'
import CaseLayoutMedia from './CaseLayoutMedia/CaseLayoutMedia'
import CaseLayoutDraggableDocument from './CaseLayoutMedia/CaseLayoutMediaDraggableDocument'

/* Styled components -------------------------------------------------------- */
const LargeTitleNoMargin = styled(LargeTitle)`
  margin-bottom: 0px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    gap: 0px;
  }
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const SummaryContainer = styled(Card)`
  padding: 10px 15px;
  width: 350px;
  flex-shrink: 0;
  overflow-y: auto;
  max-height: calc(100svh - 20px);
`

interface MediaContainerProps {
  width: number;
}

const MediaContainer = styled.div<MediaContainerProps>`
  width: ${(props) => `${props.width}px`};
  flex-shrink: 0;
  overflow-y: hidden;
  max-height: 100svh;
  position: relative;
`

const CaseContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`

const CaseLayoutSummaryOutletContainer = styled.div`
  display: flex;
  height: calc(100svh - 20px);
  width: 100%;
  gap: 10px;
  flex-grow: 0;
  overflow: auto;
  margin-top: 5px;

  @media ${(props) => props.theme.media.desktop} {
    margin-top: 0px;
  }
`

interface OutletContainerProps {
  top: number;
}

const OutletContainer = styled.div<OutletContainerProps>`
  max-height: ${(props) => `calc(100vh - ${props.top}px - 10px)`};
  overflow-y: scroll;
`

const Resizer = styled.div`
  width: 5px;
  border-radius: 4px;
  height: calc(100vh - 20px);
  flex-shrink: 0;
  background-color: ${(props) => props.theme.palette.secondary.main};
  cursor: col-resize;
`

const ResizeOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: col-resize;
  z-index: 9999;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutProps {}

const CaseLayout: React.FC<CaseLayoutProps> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { openSummary, openMedia, documentList } = useAppSelector(getLayout)
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ outletTop, setOutletTop ] = useState<number>(200)
  const [ mediaWidth, setMediaWidth ] = useState<number>(350)
  const [ isResizing, setIsResizing ] = useState<boolean>(false)

  const { currentData: documents = []} = useGetCaseDocumentListQuery({ dossier: caseId, AvecBrouillon: false })

  const toggleDrawer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    dispatch(setOpenSummary(!openSummary))
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsResizing(true)
    event.preventDefault()
    if (!openMedia) {
      dispatch(setOpenMedia(true))
    }
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (isResizing) {
      const container = document.getElementById('case-layout-outlet')
      if (container) {
        const containerRect = container.getBoundingClientRect()
        const maxMediaWidth = window.innerWidth - containerRect.left - 500
        const newWidth = Math.min(maxMediaWidth, window.innerWidth - event.clientX)

        if (newWidth >= 0) {
          setMediaWidth(newWidth)
        }
      }
    }
  }

  const handleMouseUp = () => {
    setIsResizing(false)
  }

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [ isResizing ])

  useEffect(() => {
    const calculateOffsetTop = () => {
      const element = document.getElementById('case-layout-outlet')
      if (element) {
        setOutletTop(element.offsetTop)
      }
    }

    calculateOffsetTop()

    window.addEventListener('resize', calculateOffsetTop)

    return () => {
      window.removeEventListener('resize', calculateOffsetTop)
    }
  }, [ caseId ])

  return (
    <CaseLayoutSummaryOutletContainer>
      {
        openSummary &&
          <SummaryContainer>
            <CaseLayoutSummary
              documents={documents}
              caseId={caseId}
            />
          </SummaryContainer>
      }
      <CaseContainer>
        <LargeTitleNoMargin>
          <Actions>
            {caseId}
            <ActionButtons>
              <CustomIconButton
                Icon={InfoRounded}
                onClick={toggleDrawer}
                variant="outlined"
                color="secondary"
                label="Informations sur le dossier"
              />
              <CustomIconButton
                Icon={CalendarMonth}
                onClick={() => null}
                variant="contained"
                label="Planning"
              />
              <CustomIconButton
                Icon={AssignmentTurnedIn}
                onClick={() => null}
                variant="contained"
                label="Tâches"
              />
              <CustomIconButton
                Icon={InsertDriveFile}
                onClick={() => dispatch(setOpenMedia(!openMedia))}
                variant="contained"
                label="Documents"
              />
              <CustomIconButton
                Icon={Email}
                onClick={() => navigate('mail')}
                variant="contained"
                label="Mail"
              />
              <CustomIconButton
                Icon={Sms}
                onClick={() => null}
                variant="contained"
                label="Messagerie"
              />
              <CustomIconButton
                Icon={StickyNote2}
                onClick={() => null}
                variant="contained"
                label="Messagerie"
              />
              <CustomIconButton
                Icon={MoreHoriz}
                onClick={() => null}
                variant="contained"
                label="Plus d'actions"
              />
            </ActionButtons>
          </Actions>
        </LargeTitleNoMargin>
        <CaseLayoutNavTabs />
        <OutletContainer
          id="case-layout-outlet"
          key={outletTop}
          top={outletTop}
        >
          <Outlet />
          <Footer />
        </OutletContainer>
      </CaseContainer>
      <Resizer onMouseDown={handleMouseDown} />
      {isResizing && <ResizeOverlay />}
      {
        openMedia &&
          <MediaContainer width={mediaWidth}>
            <CaseLayoutMedia />
          </MediaContainer>
      }
      {
        documentList[caseId]?.map((document, index) => (
          <CaseLayoutDraggableDocument
            doc={document}
            key={`${document.id}-${index}`}
          />
        ))
      }
    </CaseLayoutSummaryOutletContainer>
  )
}

export default CaseLayout
