/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetArticleBordereauListQuery,
  useGetMeasureUnitListQuery,
  useLazyGetMeasureQuery,
  usePostMeasureMutation,
  useGetRoomTypeListQuery,
  useLazyGetMeasurePhotosQuery,
  usePostMeasurePhotoMutation,
} from 'store/api'
import {
  isApiError,
  isApiResponse,
} from 'helpers/fetchHelpers'
import { formatApiErrorMessage } from 'helpers/formatApiErrorMessage'
import { getUuid } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CircularProgress,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import RateField from 'components/FieldWithInputAdornment/RateField'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import BackButton from 'components/BackButton/BackButton'
import MeasureArticleReadOnlyLines from './MeasureComponents/MeasureArticleReadOnlyLines'
import MeasureRoom from './MeasureComponents/MeasureRoom'
import MeasureArticleLine from './MeasureComponents/MeasureArticleLine'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import { IconButtonSize } from 'components/IconButtons/CustomIconButton/CustomIconButtonContainer'
import type {
  TypeSurface,
  Decote,
  MetreCreatePayload,
  Piece,
  Article,
  Surface,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const articleSchema = {
  id: Yup.string(),
  articlePieceIndex: Yup.number(),
  isParticulier: Yup.boolean(),
  code: Yup.string(),
  quantite: Yup.number().required('La quantité est obligatoire'),
  infos: Yup.string(),
  unite: Yup.string(),
  deleteOperation: Yup.boolean(),
}

const measureSchema = Yup.object().shape<Shape<MetreCreatePayload>>({
  commentaire: Yup.string(),
  humidite: Yup.number(),
  pieces: Yup.array(Yup.object().shape<Shape<Piece>>({
    deleteOperation: Yup.boolean(),
    id: Yup.string(),
    libelle: Yup.string(),
    type: Yup.string().required('Le type est obligatoire'),
    commentaire: Yup.string(),
    largeur: Yup.number(),
    longueur: Yup.number(),
    hauteur: Yup.number(),
    volume: Yup.number(),
    superficie: Yup.number(),
    articles: Yup.array(Yup.object().shape<Shape<Article>>(articleSchema)),
    surfaces: Yup.array(Yup.object().shape<Shape<Surface>>({
      id: Yup.string(),
      libelle: Yup.string(),
      type: Yup.mixed<TypeSurface>().required('Le type est obligatoire'),
      largeur: Yup.number(),
      longueur: Yup.number(),
      superficie: Yup.number(),
      deleteOperation: Yup.boolean(),
      decotes: Yup.array(Yup.object().shape<Shape<Decote>>({
        deleteOperation: Yup.boolean(),
        id: Yup.string(),
        libelle: Yup.string(),
        largeur: Yup.number(),
        longueur: Yup.number(),
      })),
      articles: Yup.array(Yup.object().shape<Shape<Article>>(articleSchema)),
    })),
  })),
  articles: Yup.array(Yup.object().shape<Shape<Article>>(articleSchema)),
}).required()

type MeasureForm = FormikContextType<MetreCreatePayload>

/* Styled components -------------------------------------------------------- */
const TitleButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

const StatusChips = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const HumidityContainer = styled.div`
  width: 100%;
`

const CardContainer = styled(Card)`
  padding: 0px 10px 10px;
`

/* Component declaration ---------------------------------------------------- */
interface MeasurePageProps {
  edit?: boolean;
  readOnly?: boolean;
}

const MeasurePage: React.FC<MeasurePageProps> = ({
  edit = false,
  readOnly = false,
}) => {
  const navigate = useNavigate()
  const { id = '', caseId = '' } = useParams<{id: string; caseId: string}>()
  const [ files, setFiles ] = useState<{file: File; roomId: string}[]>([])

  const defaultArticle: Article = {
    isParticulier: false,
    quantite: 0,
    articlePieceIndex: 0,
    code: '',
    deleteOperation: false,
    id: getUuid(),
    infos: '',
    unite: '1',
  }

  const defaultRoom: Piece = {
    articles: [],
    commentaire: '',
    type: 'DIV',
    hauteur: 0,
    largeur: 0,
    longueur: 0,
    deleteOperation: false,
    id: getUuid(),
    libelle: '',
    superficie: 0,
    surfaces: [],
    volume: 0,
  }

  const {
    currentData: roomTypeList = [],
    isFetching: isFetchingRoomTypeList,
  } = useGetRoomTypeListQuery()
  const {
    currentData: measureUnitList = [],
    isFetching: isFetchingMeasureUnitList,
  } = useGetMeasureUnitListQuery()
  const {
    currentData: articleBordereauList = [],
    isFetching: isFetchingArticleBordereauList,
  } = useGetArticleBordereauListQuery({ dossier: caseId })
  const [
    getMeasure,
    { currentData: measure },
  ] = useLazyGetMeasureQuery()
  const [
    getMeasurePhotos,
    { currentData: measurePhotos = []},
  ] = useLazyGetMeasurePhotosQuery()
  const [
    submitSaveMeasure,
    { isLoading: isSavingMeasure },
  ] = usePostMeasureMutation()
  const [
    submitSavePhoto,
    { isLoading: isSavingPhoto },
  ] = usePostMeasurePhotoMutation()

  const formikForm: MeasureForm = useForm<MetreCreatePayload>(
    {
      initialValues: {
        articles: [ defaultArticle ],
        commentaire: '',
        humidite: 0,
        pieces: [],
      },
      validationSchema: measureSchema,
    },
  )

  useEffect(() => {
    if (measure) {
      formikForm.setValues({
        ...formikForm.values,
        ...measure,
      })
    }
  }, [ measure ])

  useEffect(() => {
    if (!edit && !readOnly) return
    getMeasure(id)
    getMeasurePhotos(id)
  }, [ edit, readOnly ])

  const addNewArticle = () => {
    let lines = structuredClone(formikForm.values.articles || [])
    lines = [ ...lines, defaultArticle ]
    formikForm.setFieldValue('articles', lines)
  }

  const addNewRoom = () => {
    let lines = structuredClone(formikForm.values.pieces || [])
    lines = [ ...lines, { ...defaultRoom, libelle: `Pièce ${lines.length + 1}` } ]
    formikForm.setFieldValue('pieces', lines)
  }

  const onSendClick = () => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          return submitSaveMeasure({ measureId: (edit && id) ? id : `${caseId}-0`, data: formikForm.values })
        } else {
          console.error('Measure errors', errors)
        }
      })
      .then(async (response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue lors de l'enregistrement : ${formatApiErrorMessage(response.error)}.`)
        } else if (isApiResponse<string>(response)) {
          toast.success('Le métré à bien été enregistré.')
          const promises: Promise<unknown>[] = []
          files.forEach((file) => {
            promises.push(submitSavePhoto({ measureId: response.data, data: { Image: file.file, ReferencePiece: file.roomId }}))
          })
          await Promise.all(promises)
          navigate(`/dossiers/${caseId}`)
        }
      })
      .catch(console.error)
      .finally(() => formikForm.setSubmitting(false))
  }

  const isLoading = useMemo(() => isFetchingMeasureUnitList || isFetchingArticleBordereauList || isFetchingRoomTypeList, [
    isFetchingMeasureUnitList,
    isFetchingArticleBordereauList,
    isFetchingRoomTypeList,
  ])

  return (
    <Form form={formikForm}>
      <RouteTitle title={`Métré ${caseId}`} />
      <BackButton onClick={() => navigate(-1)}>
        Retourner au dossier
      </BackButton>
      <LargeTitle>
        <StatusChips>
          {(edit || readOnly) ? 'Métré' : 'Nouveau Métré'}
        </StatusChips>
        <TitleButtonContainer>
          {
            !readOnly &&
              <Button
                variant="contained"
                onClick={onSendClick}
                disabled={isSavingMeasure || isSavingPhoto}
              >
                Enregistrer
              </Button>
          }
        </TitleButtonContainer>
      </LargeTitle>
      {
        isLoading ?
          <CircularProgress /> :
          <CardContainer>
            <FormBoldTitle>
              <HumidityContainer>
                Taux d'humidité
              </HumidityContainer>
              <RateField
                name="humidite"
                onChange={(e) => formikForm.setFieldValue('humidite', e.target.value)}
                disabled={readOnly}
                size="small"
              />
            </FormBoldTitle>
            <FormBoldTitle>
              Articles
              <CustomIconButton
                Icon={Add}
                label="Ajouter un article"
                onClick={addNewArticle}
                variant="contained"
                customsize={IconButtonSize.small}
                disabled={readOnly}
              />
            </FormBoldTitle>
            {
              readOnly ?
                <MeasureArticleReadOnlyLines
                  lines={formikForm.values.articles || []}
                  measureUnitList={measureUnitList}
                /> :
                formikForm.values.articles?.map((item, index) => (
                  <MeasureArticleLine
                    key={`articles.[${index}]`}
                    index={index}
                    lines={formikForm.values.articles || []}
                    setFieldValue={formikForm.setFieldValue}
                    measureUnitList={measureUnitList}
                    articleBordereauList={articleBordereauList.map(({ libelle, ...rest }) => {return ({ ...rest, libelle: libelle.toLowerCase() })})}
                    lineName={`articles.[${index}]`}
                    linesName="articles"
                  />
                ))
            }
            <FormBoldTitle>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              name="commentaire"
              placeholder="Commentaire"
              size="small"
              disabled={readOnly}
              multiline
              rows={3}
            />
          </CardContainer>
      }
      {
        isLoading ?
          <CircularProgress /> :
          <>
            <FormBoldTitle bigger>
              Pièces
              <Button
                variant="contained"
                onClick={addNewRoom}
                disabled={readOnly}
              >
                Ajouter une pièce
              </Button>
            </FormBoldTitle>
            {
              formikForm.values.pieces?.map((room, index) => (
                <MeasureRoom
                  key={`${room.id}-${index}`}
                  setFieldValue={formikForm.setFieldValue}
                  measureUnitList={measureUnitList}
                  articleBordereauList={articleBordereauList.map(({ libelle, ...rest }) => {return ({ ...rest, libelle: libelle.toLowerCase() })})}
                  room={room}
                  rooms={formikForm.values.pieces || []}
                  readOnly={readOnly}
                  index={index}
                  roomTypeList={roomTypeList}
                  defaultArticle={defaultArticle}
                  photos={measurePhotos}
                  files={files.filter((v) => v.roomId === room.id).map((v) => v.file)}
                  setFiles={(f: File[]) => setFiles([ ...files.filter((v) => v.roomId !== room.id), ...f.map((value) => ({ file: value, roomId: room.id || '' })) ])}
                />
              ))
            }
          </>
      }
    </Form>
  )
}

export default MeasurePage
