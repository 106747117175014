/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { getUuid } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  MenuItem,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import MeasureArticleLine from './MeasureArticleLine'
import MeasureArticleReadOnlyLines from './MeasureArticleReadOnlyLines'
import MeasureAreaLine from './MeasureAreaLine'
import MeasureAreaReadOnlyLines from './MeasureAreaReadOnlyLines'

/* Type imports ------------------------------------------------------------- */
import { IconButtonSize } from 'components/IconButtons/CustomIconButton/CustomIconButtonContainer'
import type {
  Decote,
  Article,
  Bordereau,
  CodeLabel,
  Piece,
  Surface,
  TypePiece,
  ImageMetre,
} from 'API/__generated__/Api'
import { TypeSurface } from 'API/__generated__/Api'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import MeasureThumbnail from 'components/MediaRenderer/MeasureThumbnail'

/* Styled components -------------------------------------------------------- */
const RoomCardContainer = styled(Card)`
  padding: 10px 10px 10px;
  margin-bottom: 20px;
`

const RoomGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;
  padding: 5px 5px 5px 0px;
  align-items: center;
`

const AreaGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 20px;
  gap: 5px;
  align-items: center;
  margin-top: -15px;
  margin-bottom: -10px;
`

const ImageContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;
  max-height: 200px;
`

/* Component declaration ---------------------------------------------------- */
interface MeasureRoomProps {
  readOnly?: boolean;
  setFieldValue: (field: string, value: string | boolean | number | Article | Article[] | Surface | Surface[] | Decote | Decote[]) => void;
  measureUnitList: CodeLabel[];
  articleBordereauList: Bordereau[];
  roomTypeList: TypePiece[];
  room: Piece;
  rooms: Piece[];
  photos: ImageMetre[];
  files: File[];
  setFiles: (files: File[]) => void;
  index: number;
  defaultArticle: Article;
}

const MeasureRoom: React.FC<MeasureRoomProps> = ({
  readOnly = false,
  measureUnitList,
  articleBordereauList,
  roomTypeList,
  room,
  rooms,
  index,
  setFieldValue,
  defaultArticle,
  photos,
  files,
  setFiles,
}) => {

  const defaultArea: Surface = {
    type: TypeSurface.Mur,
    longueur: 0,
    largeur: 0,
    superficie: 0,
    libelle: '',
    articles: [],
    decotes: [],
    id: getUuid(),
    deleteOperation: false,
  }

  const defaultDecote: Decote = {
    deleteOperation: false,
    id: getUuid(),
    longueur: 0,
    largeur: 0,
    libelle: '',
  }

  const addNewArticleInRoom = () => {
    let lines = structuredClone(room.articles || [])
    lines = [ ...lines, defaultArticle ]
    setFieldValue(`pieces.[${index}].articles`, lines)
  }

  const addNewAreaInRoom = () => {
    let lines = structuredClone(room.surfaces || [])
    lines = [ ...lines, { ...defaultArea, libelle: `Mur ${lines.length + 1}` } ]
    setFieldValue(`pieces.[${index}].surfaces`, lines)
  }

  const addNewArticleInArea = (areaIndex: number) => {
    let lines = structuredClone(room.surfaces?.[areaIndex].articles || [])
    lines = [ ...lines, defaultArticle ]
    setFieldValue(`pieces.[${index}].surfaces.[${areaIndex}].articles`, lines)
  }

  const addNewDecoteInArea = (areaIndex: number) => {
    let lines = structuredClone(room.surfaces?.[areaIndex].decotes || [])
    lines = [ ...lines, { ...defaultDecote, libelle: `Décote ${lines.length + 1}` } ]
    setFieldValue(`pieces.[${index}].surfaces.[${areaIndex}].decotes`, lines)
  }

  const onCalculateRoom = (roomIndex: number, valueType: 'hauteur' | 'longueur' | 'largeur', value: number | string) => {
    const realValue = typeof value === 'string' ? parseFloat(value || '0') : value

    setFieldValue(`pieces.[${roomIndex}].${valueType}`, value)
    if (!rooms[roomIndex]) return
    const { longueur = 0, largeur = 0, hauteur = 0 } = rooms[roomIndex]
    if (valueType === 'hauteur') {
      const volume = realValue * (longueur || 0) * (largeur || 0)
      setFieldValue(`pieces.[${roomIndex}].volume`, volume)
    } else {
      if (valueType === 'longueur') {
        const volume = realValue * (hauteur || 0) * (largeur || 0)
        const area = realValue * (largeur || 0)
        setFieldValue(`pieces.[${roomIndex}].volume`, volume)
        setFieldValue(`pieces.[${roomIndex}].superficie`, area)
      } else {
        const volume = realValue * (hauteur || 0) * (longueur || 0)
        const area = realValue * (longueur || 0)
        setFieldValue(`pieces.[${roomIndex}].volume`, volume)
        setFieldValue(`pieces.[${roomIndex}].superficie`, area)
      }
    }
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...files, ...Object.values(e.target.files ?? {}) ])
    }
  }

  const onDeleteFile = (fileIndex: number) => {
    const newFiles = [ ...files ]
    newFiles.splice(fileIndex, 1)
    setFiles(newFiles)
  }

  return (
    <RoomCardContainer>
      <Field
        component={TextField}
        name={`pieces.[${index}].libelle`}
        placeholder="Nom de la pièce"
        size="small"
        disabled={readOnly}
      />
      <RoomGridContainer>
        <div>
          <FormBoldTitle>
            Pièce
          </FormBoldTitle>
          <Field
            component={Select}
            name={`pieces.[${index}].type`}
            displayEmpty
            size="small"
            disabled={readOnly}
          >
            {
              roomTypeList.map((value, index) => (
                <MenuItem
                  value={value.code}
                  key={`${value.code}-${index}`}
                >
                  {value.libelle}
                </MenuItem>
              ))
            }
          </Field>
        </div>
        <div>
          <FormBoldTitle>
            Longueur
          </FormBoldTitle>
          <NumberField
            name={`pieces.[${index}].longueur`}
            disabled={readOnly}
            size="small"
            onChange={(e) => onCalculateRoom(index, 'longueur', e.target.value)}
          />
        </div>
        <div>
          <FormBoldTitle>
            Largeur
          </FormBoldTitle>
          <NumberField
            name={`pieces.[${index}].largeur`}
            disabled={readOnly}
            size="small"
            onChange={(e) => onCalculateRoom(index, 'largeur', e.target.value)}
          />
        </div>
        <div>
          <FormBoldTitle>
            Hauteur
          </FormBoldTitle>
          <NumberField
            name={`pieces.[${index}].hauteur`}
            disabled={readOnly}
            size="small"
            onChange={(e) => onCalculateRoom(index, 'hauteur', e.target.value)}
          />
        </div>
        <div>
          <FormBoldTitle>
            Superficie
          </FormBoldTitle>
          <NumberField
            name={`pieces.[${index}].superficie`}
            onChange={(e) => setFieldValue(`pieces.[${index}].superficie`, e.target.value)}
            disabled={readOnly}
            size="small"
          />
        </div>
        <div>
          <FormBoldTitle>
            Volume
          </FormBoldTitle>
          <NumberField
            name={`pieces.[${index}].volume`}
            onChange={(e) => setFieldValue(`pieces.[${index}].volume`, e.target.value)}
            disabled={readOnly}
            size="small"
          />
        </div>
      </RoomGridContainer>
      <FormBoldTitle>
        Surfaces
        <CustomIconButton
          Icon={Add}
          label="Ajouter une surface"
          onClick={addNewAreaInRoom}
          variant="contained"
          customsize={IconButtonSize.small}
          disabled={readOnly}
        />
      </FormBoldTitle>
      <AreaGridContainer>
        <FormBoldTitle smaller>
          Libellé
        </FormBoldTitle>
        <FormBoldTitle smaller>
          Type
        </FormBoldTitle>
        <FormBoldTitle smaller>
          Longueur
        </FormBoldTitle>
        <FormBoldTitle smaller>
          Largeur
        </FormBoldTitle>
        <FormBoldTitle smaller>
          Superficie
        </FormBoldTitle>
      </AreaGridContainer>
      {
        readOnly ?
          <MeasureAreaReadOnlyLines lines={room.surfaces || []} /> :
          room.surfaces?.map((item, areaIndex) => (
            <MeasureAreaLine
              key={`pieces.[${index}].surfaces.[${areaIndex}]`}
              index={areaIndex}
              lines={room.surfaces || []}
              setFieldValue={setFieldValue}
              lineName={`pieces.[${index}].surfaces.[${areaIndex}]`}
              linesName={`pieces.[${index}].surfaces`}
              addNewArticleInArea={() => addNewArticleInArea(areaIndex)}
              addNewDecoteInArea={() => addNewDecoteInArea(areaIndex)}
              articleBordereauList={articleBordereauList}
              readOnly={readOnly}
              measureUnitList={measureUnitList}
            />
          ))
      }
      <FormBoldTitle>
        Articles
        <CustomIconButton
          Icon={Add}
          label="Ajouter un article"
          onClick={addNewArticleInRoom}
          variant="contained"
          customsize={IconButtonSize.small}
          disabled={readOnly}
        />
      </FormBoldTitle>
      {
        readOnly ?
          <MeasureArticleReadOnlyLines
            lines={room.articles || []}
            measureUnitList={measureUnitList}
          /> :
          room.articles?.map((item, articleIndex) => (
            <MeasureArticleLine
              key={`pieces.[${index}].articles.[${articleIndex}]`}
              index={articleIndex}
              lines={room.articles || []}
              setFieldValue={setFieldValue}
              measureUnitList={measureUnitList}
              articleBordereauList={articleBordereauList}
              lineName={`pieces.[${index}].articles.[${articleIndex}]`}
              linesName={`pieces.[${index}].articles`}
            />
          ))
      }
      <FormBoldTitle>
        Photos
        <AttachmentButton
          onChange={handleOnFileChange}
          name={`pieces.[${index}].photos`}
          accept="image/*"
        >
          <CustomIconButton
            Icon={Add}
            label="Ajouter une photo"
            component="span"
            variant="contained"
            customsize={IconButtonSize.small}
            disabled={readOnly}
          />
        </AttachmentButton>
      </FormBoldTitle>
      <ImageContainer>
        {
          photos.map((value, index) => (
            <MeasureThumbnail
              image={value}
              key={`${value.referenceMetre}-${index}`}
            />
          ))
        }
        {
          files.map((value, index) => (
            <MeasureThumbnail
              file={value}
              key={`${value.name}-${index}`}
              onDeleteClick={() => onDeleteFile(index)}
            />
          ))
        }
      </ImageContainer>
      <FormBoldTitle>
        Commentaire
      </FormBoldTitle>
      <Field
        component={TextField}
        name={`pieces.[${index}].commentaire`}
        placeholder="Commentaire"
        size="small"
        disabled={readOnly}
        multiline
        rows={3}
      />
    </RoomCardContainer>
  )
}

export default MeasureRoom
