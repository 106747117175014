/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  Article,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  important?: boolean;
  line?: boolean;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(5, 1fr) 2fr;
  gap: 25px;
  align-items: center;
  font-weight: ${(props) => props.important ? 'bold' : 'normal'};
  text-transform: ${(props) => props.important ? 'uppercase' : 'none'};
  border-bottom: ${(props) => props.line ? `2px solid ${props.theme.colors.grey}` : ''};
  margin: 5px 0px;
`

const CardLineContainer = styled(Card)`
  margin: 20px 0px;
  padding: 3px 10px 0px;
`

/* Component declaration ---------------------------------------------------- */
interface MeasureArticleReadOnlyLinesProps {
  lines: Article[];
  measureUnitList: CodeLabel[];
}

const MeasureArticleReadOnlyLines: React.FC<MeasureArticleReadOnlyLinesProps> = ({
  lines,
  measureUnitList,
}) => {
  return (
    <CardLineContainer>
      {
        lines.map((item, index, { length }) => (
          <GridContainer
            key={index}
            line={index !== length - 1}
          >
            <div>
              {item.code}
            </div>
            <div>
              {item.isParticulier ? 'Particulier' : 'Assurance'}
            </div>
            <div>
              {measureUnitList.find((l) => l.code === item.unite)?.libelle || item.unite}
            </div>
            <div>
              {item.quantite}
            </div>
            <div>
              {item.infos}
            </div>
          </GridContainer>
        ))
      }
    </CardLineContainer>
  )
}

export default MeasureArticleReadOnlyLines
