/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { getMediaFileType } from 'helpers/getMediaFileType'

/* Component imports -------------------------------------------------------- */
import MediaProcessorFallback from 'components/MediaProcessors/MediaProcessorFallback/MediaProcessorFallback'

/* Type imports ------------------------------------------------------------- */
import type { ElementMediatheque } from 'API/__generated__/Api'

/* Lazy component imports --------------------------------------------------- */
const PdfProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/PdfProcessor/PdfProcessor' /* webpackChunkName: "pdf" */))
const ImageProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/ImageProcessor/ImageProcessor' /* webpackChunkName: "image" */))

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  height: 100%;
  overflow-y: hidden;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutMediaFileDisplayProps {
  document: ElementMediatheque;
  edit: boolean;
}

const CaseLayoutMediaFileDisplay: React.FC<CaseLayoutMediaFileDisplayProps> = ({ document, edit }) => {
  return (
    <Container>
      {
        getMediaFileType(document) === 'img' &&
        (
          edit ?
            <MediaProcessorFallback type="image">
              <ImageProcessor source={document.url || ''} />
            </MediaProcessorFallback> :
            <Image
              src={document.url || ''}
              alt={document.libelle || document.fileName}
            />
        )
      }
      {
        getMediaFileType(document) === 'pdf' &&
        (
          edit ?
            <MediaProcessorFallback type="pdf">
              <PdfProcessor documentPath={document.url || ''} />
            </MediaProcessorFallback> :
            <iframe
              src={document.url || ''}
              width="100%"
              height="100%"
              title={document.libelle || document.fileName}
            />
        )
      }
    </Container>
  )
}

export default CaseLayoutMediaFileDisplay
