/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetDepartmentListQuery,
  useGetCaseInsuranceCompanyListQuery,
  useGetInvoiceListQuery,
  useGetDisasterListQuery,
} from 'store/api'
import Table, {
  exportToXLSX,
  getRowsComparator,
} from 'components/Table/Table'
import {
  caseTabs,
  CaseTabType,
  getInvoiceStatusColor,
} from 'helpers/caseUtils'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import { getCaseListPeriod } from 'store/slices/caseListFilterSlice'
import { formatPrice } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
} from '@mui/material'
import {
  Search,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import { TextField } from 'formik-mui'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import RoundedChip from 'components/RoundedChip/RoundedChip'
import CheckableSortingButton from 'components/CheckableSortingButton/CheckableSortingButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import InvoiceListFilters from './DashboardInvoiceListComponents/DashboardInvoiceListFilters'
import InvoiceListMobileCard from './DashboardInvoiceListComponents/DashboardInvoiceListMobileCard'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
} from 'formik'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'components/Table/Table'
import type {
  FacturesDashboardListParams,
  EtatFacture,
  DashboardFacture,
  EtatFactureEnumLabel,
  Franchise,
  Acompte,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const invoiceListSchema = Yup.mixed<FacturesDashboardListParams>().required()

type InvoiceListForm = FormikContextType<FacturesDashboardListParams>

interface OpenedMenu {
  filter: boolean;
  sort: boolean;
}

/* Internal variable declarations ------------------------------------------- */
const RESULT_LIMIT = 10

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 85% 14%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgrey};
`

const CustomChip = styled(RoundedChip)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;

  .status-chip {
    font-size: .8rem;
    width: 100%;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
`

const SearchExportContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 430px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DashboardInvoiceListPageProps {}

const DashboardInvoiceListPage: React.FC<DashboardInvoiceListPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const period = useAppSelector(getCaseListPeriod)
  const [ invoiceList, setInvoiceList ] = useState<DashboardFacture[]>([])
  const [ openedMenu, setOpenedMenu ] = useState<OpenedMenu>({ filter: false, sort: false })
  const [ statusFilters, setStatusFilters ] = useState<{status: EtatFactureEnumLabel; amount: number | null}[]>([])
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ order, setOrder ] = useState<Order>('asc')
  const [ orderBy, setOrderBy ] = useState<DataName>('')
  const [ selectedRows, setSelectedRows ] = useState<DashboardFacture[]>([])

  const {
    currentData: companyList = [],
    isFetching: isFetchingCompanyList,
  } = useGetCaseInsuranceCompanyListQuery()
  const {
    currentData: departmentList = [],
    isFetching: isFetchingDepartmentList,
  } = useGetDepartmentListQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterListQuery()

  const formikForm: InvoiceListForm = useForm<FacturesDashboardListParams>(
    {
      initialValues: {
        Limite: RESULT_LIMIT,
        CompagnieAssurance: [],
        DateDebut: period,
        DateFin: new Date().toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        Departement: '',
        IndexDebut: 0,
        NatureSinistre: [],
        RechercheLibre: '',
        Etats: [],
      },
      validationSchema: invoiceListSchema,
    },
  )

  const parseFilters = (params: FacturesDashboardListParams) => {
    const result = structuredClone(params)

    for (const key in params) {
      const typedKey = key as keyof FacturesDashboardListParams
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[typedKey] = params[typedKey] === null ? '' : params[typedKey]
    }

    return result
  }

  const {
    currentData: invoiceResultList,
    isFetching: isFetchingInvoiceResultList,
  } = useGetInvoiceListQuery(parseFilters(formikForm.values))

  const saveLastPath = () => {
    dispatch(setRouterLastPath(location.pathname))
  }

  const handleNavigate = (caseId: string) => {
    saveLastPath()
    navigate(`/dossier/${caseId}/${caseTabs.find((v) => v.type === CaseTabType.CaseInvoice)?.path}`)
  }

  useEffect(() => {
    if (invoiceResultList?.resultats?.length !== undefined) {
      setInvoiceList(invoiceResultList?.resultats?.slice().sort(getRowsComparator(order, orderBy)) || [])
    }
    if (invoiceResultList?.totalResultats !== undefined && invoiceResultList.totalResultats !== totalRows) {
      setTotalRows(invoiceResultList.totalResultats)
    }
    if (invoiceResultList?.nombreResultatParStatut) {
      const status: {status: EtatFactureEnumLabel; amount: number | null}[] = []

      invoiceResultList?.nombreResultatParStatut?.forEach(({ nombreResultat, etat }) =>
        etat && status.push({ status: etat, amount: nombreResultat || 0 }),
      ),

      setStatusFilters(status)
    }
  }, [ invoiceResultList ])

  const cols: ColumnHeader<DashboardFacture>[] = [
    {
      id: 'assure',
      label: 'Assuré',
    },
    {
      id: 'donneurDordre',
      label: "Donneur d'ordre",
    },
    {
      id: 'refMandant',
      label: 'Ref mandant',
    },
    {
      id: 'ville',
      label: 'Ville',
    },
    {
      id: 'numeroFacture',
      label: 'N° de facture',
      render: (value: string, row) => (
        <Link
          href={`/dossier/${(row as DashboardFacture).dossier}/${caseTabs.find((v) => v.type === CaseTabType.CaseInvoice)?.path}`}
          onClick={(e) => {e.stopPropagation(); saveLastPath()}}
        >
          {value}
        </Link>
      ),
    },
    {
      id: 'type',
      label: 'Type',
    },
    {
      id: 'montantHT',
      label: 'Montant HT',
      render: (value: number) => formatPrice(value),
      renderForExport: (value: number) => formatPrice(value),
      align: 'right',
    },
    {
      id: 'franchise',
      label: 'Franchise',
      render: (value: Franchise) => value.montant ? `${formatPrice(value.montant)} - ${value.recuperee ? 'Récupérée' : 'Non récupérée'}` : '',
      renderForExport: (value: Franchise) => value.montant ? `${formatPrice(value.montant)} - ${value.recuperee ? 'Récupérée' : 'Non récupérée'}` : '',
    },
    {
      id: 'acompte',
      label: 'Acompte',
      render: (value: Acompte) => value.montant ? `${formatPrice(value.montant)} - ${value.recupere ? 'Récupére' : 'Non récupéré'}` : '',
      renderForExport: (value: Acompte) => value.montant ? `${formatPrice(value.montant)} - ${value.recupere ? 'Récupéré' : 'Non récupéré'}` : '',
    },
    {
      id: 'etat',
      label: 'Statut',
      sortId: 'etat.code',
      render: (status: EtatFactureEnumLabel) => {
        return (
          <StatusChipContainer>
            <ColoredSquareChip
              color={getInvoiceStatusColor(status.code)}
              className="status-chip"
            >
              {status.libelle}
            </ColoredSquareChip>
          </StatusChipContainer>
        )
      },
      renderForExport: (status: EtatFactureEnumLabel) => status.libelle || '',
    },
  ]

  const exportDataToXLSX = () => {
    const list = selectedRows.length > 0 ? selectedRows : invoiceList

    exportToXLSX(cols.filter((col) => !col.removeFromExport), list, `factures_${new Date().toISOString().replace(/[-T:]/g, '').slice(0, 14)}`)
  }

  const onStatusFilterClick = (status: EtatFacture) => {
    if (formikForm.values.Etats?.includes(status)) {
      formikForm.setFieldValue('Etats', formikForm.values.Etats.filter((stat) => stat !== status))
    } else {
      formikForm.setFieldValue('Etats', [ ...formikForm.values.Etats || [], status ])
    }
  }

  const handleSort = (property: DataName) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setInvoiceList(invoiceList.slice().sort(getRowsComparator(isAsc ? 'desc' : 'asc', property)))
    setOpenedMenu({ ...openedMenu, sort: false })
  }

  return (
    <Form form={formikForm}>
      <Card>
        <CardContent>
          <LargeTitle>
            Factures
            <SearchExportContainer>
              <Field
                component={TextField}
                name="RechercheLibre"
                placeholder="Rechercher dans le tableau"
                size="small"
                InputProps={
                  {
                    endAdornment: (
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    ),
                  }
                }
              />
              <LongButton
                variant="contained"
                onClick={exportDataToXLSX}
              >
                Exporter
              </LongButton>
            </SearchExportContainer>
          </LargeTitle>
          <FilterDesktopContainer>
            <InvoiceListFilters
              companyList={companyList}
              isFetchingCompanyList={isFetchingCompanyList}
              disasterNatureList={disasterNatureList}
              isFetchingDisasterNatureList={isFetchingDisasterNatureList}
              departmentList={departmentList}
              isFetchingDepartmentList={isFetchingDepartmentList}
            />
          </FilterDesktopContainer>
          <FilterMobileContainer>
            <BoldSeparator />
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, filter: !openedMenu.filter })}>
              Filtres
              <DropDownArrow open={openedMenu.filter} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.filter}
              timeout="auto"
              unmountOnExit
            >
              <InvoiceListFilters
                companyList={companyList}
                isFetchingCompanyList={isFetchingCompanyList}
                disasterNatureList={disasterNatureList}
                isFetchingDisasterNatureList={isFetchingDisasterNatureList}
                departmentList={departmentList}
                isFetchingDepartmentList={isFetchingDepartmentList}
              />
            </Collapse>
            <BoldSeparator />
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, sort: !openedMenu.sort })}>
              Trier par
              <DropDownArrow open={openedMenu.sort} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.sort}
              timeout="auto"
              unmountOnExit
            >
              {
                cols.map((col) => (
                  <CheckableSortingButton
                    key={col.id}
                    checked={orderBy === col.id}
                    label={col.label}
                    onChange={handleSort(col.sortId || col.id)}
                    order={order}
                  />
                ))
              }
            </Collapse>
            <BoldSeparator />
          </FilterMobileContainer>
          <FormBoldTitle>
            Filtrer par statut
          </FormBoldTitle>
          <ScrollableFiltersContainer>
            {
              statusFilters.map((filter) => (
                <CustomChip
                  variant={formikForm.values.Etats?.includes(filter.status.code) ? 'contained' : 'outlined'}
                  key={filter.status.code}
                  onClick={() => onStatusFilterClick(filter.status.code)}
                >
                  {filter.status.libelle}
                  <b>
                    {filter.amount}
                  </b>
                </CustomChip>
              ))
            }
          </ScrollableFiltersContainer>
        </CardContent>
      </Card>
      <LoadingOverlay isLoading={isFetchingInvoiceResultList}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={invoiceList}
              setRows={(rows) => setInvoiceList(rows)}
              colHeaders={cols}
              onRowClick={(row) => handleNavigate(row.dossier)}
              limit={RESULT_LIMIT}
              pagination={
                {
                  setServerLimit: (limit) => formikForm.setFieldValue('Limite', limit),
                  setServerStartIndex: (startIndex) => formikForm.setFieldValue('IndexDebut', startIndex),
                  totalRows,
                }
              }
              sorting={{ setOrder, setOrderBy }}
              selected={selectedRows}
              setSelected={(value) => setSelectedRows(value)}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          {
            invoiceList.map((value, index) => (
              <InvoiceListMobileCard
                key={`${value.numeroFacture}-${index}`}
                invoice={value}
                handleNavigate={() => handleNavigate(value.dossier)}
              />
            ))
          }
        </MobileCardContainer>
      </LoadingOverlay>
    </Form>
  )
}

export default DashboardInvoiceListPage
