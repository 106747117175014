/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { Decote } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  important?: boolean;
  line?: boolean;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
  align-items: center;
  font-weight: ${(props) => props.important ? 'bold' : 'normal'};
  text-transform: ${(props) => props.important ? 'uppercase' : 'none'};
  border-bottom: ${(props) => props.line ? `2px solid ${props.theme.colors.grey}` : ''};
  margin: 5px 0px;
`

const CardLineContainer = styled(Card)`
  margin: 20px 0px;
  padding: 3px 10px 0px;
`

/* Component declaration ---------------------------------------------------- */
interface MeasureDecoteReadOnlyLinesProps {
  lines: Decote[];
}

const MeasureDecoteReadOnlyLines: React.FC<MeasureDecoteReadOnlyLinesProps> = ({ lines }) => {
  return (
    <CardLineContainer>
      {
        lines.map((item, index, { length }) => (
          <GridContainer
            key={index}
            line={index !== length - 1}
          >
            <div>
              {item.libelle}
            </div>
            <div>
              {item.longueur}
            </div>
            <div>
              {item.largeur}
            </div>
          </GridContainer>
        ))
      }
    </CardLineContainer>
  )
}

export default MeasureDecoteReadOnlyLines
