/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import fuzzysort from 'fuzzysort'

/* Module imports ----------------------------------------------------------- */
import {
  getUuid,
  handleNumberVerification,
} from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material'
import { MoreVertRounded } from '@mui/icons-material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  Bordereau,
  CodeLabel,
  Article,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  isRoom: boolean;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => props.isRoom ? '80px' : ''} 1fr 2fr 1fr 1fr 2fr 20px;
  gap: 5px;
  padding: 5px;
  align-items: center;
`

const IconButton = styled(Button)`
  align-self: center;
  min-width: auto;
  width: 20px !important;
`

const MenuOverflow = styled(Menu)`
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    overflow-x: auto;
    max-height: 30vh;
  }
`

const Table = styled.table`
  padding: 0px 10px;
  border-spacing: 0px;

  thead {
    td {
      font-weight: bold;
    }
  }

  td {
    border: 1px solid ${(props) => props.theme.colors.inputgrey};
    padding: 5px 10px;
  }
`

const TableRow = styled.tr`
  font-size: 14px;
  height: 30px;

  &:hover {
    background-color: ${(props) => props.theme.colors.inputgrey};
    cursor: pointer;
  }

  td:first-of-type {
    font-weight: bold;
    color: ${(props) => props.theme.palette.primary.main};
  }
`

/* Component declaration ---------------------------------------------------- */
interface MeasureArticleLineProps {
  lines: Article[];
  setFieldValue: (field: string, value: string | Article | Article[] | boolean) => void;
  measureUnitList: CodeLabel[];
  articleBordereauList: Bordereau[];
  index: number;
  lineName: string;
  linesName: string;
  isArea?: boolean;
}

const MeasureArticleLine: React.FC<MeasureArticleLineProps> = ({
  lines,
  setFieldValue,
  measureUnitList,
  index,
  articleBordereauList,
  lineName,
  linesName,
  isArea = false,
}) => {
  const [ anchorLineMenu, setAnchorLineMenu ] = useState<null | SVGElement>(null)
  const [ anchorBordereauMenu, setAnchorBordereauMenu ] = useState<null | HTMLElement>(null)
  const [ results, setResults ] = useState<Bordereau[]>([])

  const filterOptions = (options: Bordereau[], inputValue: string) => {
    const basicFilter = options.filter((op) => op.libelle.includes(inputValue.toLowerCase()))

    const opt = {
      limit: 100, // don't return more results than you need!
      threshold: -100, // don't return bad results
      all: true,
      keys: [ 'code', 'libelle', 'description' ],
    }

    const fuzzyResults = fuzzysort.go(inputValue, options, opt).map(({ obj }) => obj).filter((op) => !basicFilter.some((v) => v.code === op.code))
    setResults([ ...basicFilter, ...fuzzyResults ])
  }

  const searchBordereau = (value: string) => {
    setFieldValue(`${lineName}.code`, value)

    if (value && value.length > 2) {
      filterOptions(articleBordereauList, value)
      const field = document.getElementById(`${lineName}.code`)
      setAnchorBordereauMenu(field)
    }
  }

  const handleBordereauMenuClose = () => {
    setAnchorBordereauMenu(null)
  }

  const handleLineMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    event.stopPropagation()
    setAnchorLineMenu(event.currentTarget)
  }

  const handleLineMenuClose = (): void => {
    setAnchorLineMenu(null)
  }

  const handleLineValueChange = (value: string): void => {
    if (!lines?.[index]) {
      return
    }

    const lastIsDot = value?.[value.length - 1] === '.'
    const lastIsDotZero = value?.[value.length - 1] === '0' && value?.[value.length - 2] === '.'
    const newValue = handleNumberVerification(value, 2)

    const formatValue = () => `${newValue}${lastIsDot ? '.' : lastIsDotZero ? '.0' : ''}`

    setFieldValue(`${lineName}.quantite`, formatValue())
  }

  const onSelectBordereauClick = (bordereau: Bordereau) => {
    const newLine: Article = {
      ...structuredClone(lines?.[index]),
      code: bordereau.code,
      infos: bordereau.description,
      quantite: 1,
    }

    setFieldValue(`${lineName}`, newLine)
    handleBordereauMenuClose()
  }

  const onDuplicateClick = () => {
    if (!lines?.length) return
    const newArray = [ ...lines ]
    const duplicateItem = { ...lines[index], id: getUuid() }

    newArray.splice(index + 1, 0, duplicateItem)
    setFieldValue(linesName, newArray)
    handleLineMenuClose()
  }

  const onDeleteClick = () => {
    if (!lines?.length) return
    const newArray = [ ...lines ]

    newArray.splice(index, 1)
    setFieldValue(linesName, newArray)
    handleLineMenuClose()
  }

  const isDisabled = useMemo(() => {
    return articleBordereauList.some((l) => l.code === lines?.[index]?.code)
  }, [ lines?.[index]?.code ])

  const createNewBooleanOptions = (): SegmentedButtonOption<boolean>[] => [ { value: false, label: 'Assurance' }, { value: true, label: 'Particulier' } ]

  return (
    <GridContainer isRoom={isArea}>
      {
        isArea &&
          <div>
            Article
          </div>
      }
      <Field
        component={TextField}
        id={`${lineName}.code`}
        name={`${lineName}.code`}
        value={lines?.[index].code || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchBordereau(e.target.value)}
        placeholder="Code"
        size="small"
      />
      <SegmentedButtons
        options={createNewBooleanOptions()}
        selectedOption={lines?.[index].isParticulier || false}
        setSelectedOption={(v) => setFieldValue(`${lineName}.isParticulier`, v)}
        smaller
      />
      <Field
        component={Select}
        name={`${lineName}.unite`}
        displayEmpty
        size="small"
        disabled={isDisabled}
      >
        {
          measureUnitList.map((value, index) => (
            <MenuItem
              value={value.code}
              key={`${value.code}-${index}`}
            >
              {value.libelle}
            </MenuItem>
          ))
        }
      </Field>
      <NumberField
        name={`${lineName}.quantite`}
        onChange={(e) => handleLineValueChange(e.target.value)}
        size="small"
      />
      <Field
        component={TextField}
        name={`${lineName}.infos`}
        placeholder="Infos"
        size="small"
      />
      <IconButton
        variant="text"
        size="small"
      >
        <MoreVertRounded
          fontSize="large"
          onClick={handleLineMenuClick}
        />
      </IconButton>
      <MenuOverflow
        anchorEl={anchorBordereauMenu}
        open={Boolean(anchorBordereauMenu)}
        onClose={handleBordereauMenuClose}
        disableAutoFocus
      >
        <Table>
          <thead>
            <tr>
              <td>
                Article
              </td>
              <td>
                Prix Unitaire
              </td>
              <td>
                Description
              </td>
            </tr>
          </thead>
          <tbody>
            {
              results.map((bordereau, index) => (
                <TableRow
                  key={`${bordereau.code}-${index}`}
                  onClick={() => onSelectBordereauClick(bordereau)}
                >
                  <td>
                    {bordereau.code}
                  </td>
                  <td>
                    {`${bordereau.prixUnitaire?.toFixed(2)}€`}
                  </td>
                  <td>
                    {bordereau.description}
                  </td>
                </TableRow>
              ))
            }
          </tbody>
        </Table>
      </MenuOverflow>
      <Menu
        anchorEl={anchorLineMenu}
        open={Boolean(anchorLineMenu)}
        onClose={handleLineMenuClose}
        onClick={(e): void => e.stopPropagation()}
      >
        <MenuItem onClick={onDuplicateClick}>
          Dupliquer
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          Supprimer
        </MenuItem>
      </Menu>
    </GridContainer>
  )
}

export default MeasureArticleLine
