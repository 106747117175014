/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getChipColor } from 'components/ColoredSquareChip/ColoredSquareChip'
import { getWorkflowStatusColor } from 'helpers/caseUtils'

/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'components/ColoredSquareChip/ColoredSquareChip'
import type { WorkflowEtat } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`

interface LabelProps {
  color: ChipColor;
}

const Label = styled.div<LabelProps>`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => getChipColor(props.color, '', props.theme)};
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutWorkflowJalonProps {
  label?: string | null;
  date?: string | null;
  state: WorkflowEtat;
}

const CaseLayoutWorkflowJalon: React.FC<CaseLayoutWorkflowJalonProps> = ({
  label,
  date,
  state,
}) => {
  return (
    <Container>
      <Label color={getWorkflowStatusColor(state)}>
        {label}
      </Label>
      {DateUtils.APIStrToLocalDateString(date)}
    </Container>
  )
}

export default CaseLayoutWorkflowJalon
