/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import Table, { getRowsComparator } from 'components/Table/Table'
import {
  useDeleteDirectoryCategoryMutation,
  useGetDirectoryCategoryListQuery,
  usePatchDirectoryCategoryMutation,
  usePostDirectoryCategoryMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  Delete,
  Edit,
} from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'components/Table/Table'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  FamilleAnnuaire,
  FamilleAnnuaireParametrageAnnuaireCreatePayload,
} from 'API/__generated__/Api'
import { TypePersonneEnum } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const tableSchema = Yup.object({
  filters: Yup.object({}).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type TableSchema = Yup.InferType<typeof tableSchema>

type TableForm = FormikContextType<TableSchema>

const settingsSchema = Yup.object().shape<Shape<FamilleAnnuaireParametrageAnnuaireCreatePayload>>({
  code: Yup.string().required('Le code est obligatoire').length(2, 'Le code doit être composé de 2 lettres'),
  libelle: Yup.string().required('Le libelle est obligatoire'),
  professionnel: Yup.boolean(),
  visible: Yup.boolean(),
  typePersonne: Yup.mixed<TypePersonneEnum>(),
})

type SettingsForm = FormikContextType<FamilleAnnuaireParametrageAnnuaireCreatePayload>

/* Styled components -------------------------------------------------------- */
const DialogDirectoryCategoryContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

const DualContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const FlexContainer = styled.div`
  display: flex;
  gap: 5px;
`

const Container = styled.div`
  width: 100%;
`

const TableCardContentContainer = styled.div`
  padding: 0px 15px 5px;
`

/* Component declaration ---------------------------------------------------- */
interface SettingsDirectoryCategoriesProps {}

const SettingsDirectoryCategories: React.FC<SettingsDirectoryCategoriesProps> = () => {
  const [ openModal, setOpenModal ] = useState<boolean>(false)
  const [ edit, setEdit ] = useState<FamilleAnnuaire | null>(null)
  const [ filteredList, setFilteredList ] = useState<FamilleAnnuaire[]>([])

  const [
    submitDirectoryCategory,
  ] = usePostDirectoryCategoryMutation()
  const [
    updateDirectoryCategory,
  ] = usePatchDirectoryCategoryMutation()
  const [
    deleteDirectoryCategory,
  ] = useDeleteDirectoryCategoryMutation()
  const {
    currentData: settingsList = [],
    isFetching: isFetchingSettingsList,
  } = useGetDirectoryCategoryListQuery()

  const onSubmit = (values: FamilleAnnuaireParametrageAnnuaireCreatePayload, { setSubmitting }: FormikHelpers<FamilleAnnuaireParametrageAnnuaireCreatePayload>) => {
    (edit ? updateDirectoryCategory({ code: values.code, data: values }) : submitDirectoryCategory(values))
      .then((response) => {
        if (!isApiError(response)) {
          setOpenModal(false)
          setEdit(null)
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onDelete = (code: string) => {
    deleteDirectoryCategory(code).catch(console.error)
  }

  const tableForm: TableForm = useForm<TableSchema>(
    {
      initialValues: {
        filters: {},
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: tableSchema,
    },
  )

  const settingsForm: SettingsForm = useForm<FamilleAnnuaireParametrageAnnuaireCreatePayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        code: '',
        libelle: '',
        professionnel: false,
        typePersonne: TypePersonneEnum.None,
        visible: true,
      },
      validationSchema: settingsSchema,
    },
  )

  const cols: ColumnHeader<FamilleAnnuaire>[] = [
    {
      id: 'code',
      label: 'Code',
    },
    {
      id: 'libelle',
      label: 'Libellé',
    },
    {
      id: 'professionnel',
      label: 'Professionel ?',
      render: (value: boolean) => value ? 'Oui' : 'Non',
      renderForExport: (value: boolean) => value ? 'Oui' : 'Non',
    },
    {
      id: 'visible',
      label: 'Visible ?',
      render: (value: boolean) => value ? 'Oui' : 'Non',
      renderForExport: (value: boolean) => value ? 'Oui' : 'Non',
    },
    {
      id: 'typePersonne',
      label: 'Type de personne',
    },
    {
      id: 'code',
      label: 'Actions',
      removeFromExport: true,
      render: (value: string, row) => (
        <FlexContainer>
          <CustomIconButton
            Icon={Edit}
            variant="outlined"
            label="Modifier"
            onClick={() => {setOpenModal(true); setEdit(row as FamilleAnnuaire)}}
          />
          <CustomIconButton
            Icon={Delete}
            variant="contained"
            color="error"
            label="Supprimer"
            onClick={() => onDelete(value)}
          />
        </FlexContainer>
      ),
    },
  ]

  useEffect(() => {
    if (edit) {
      settingsForm.setValues(edit)
    } else {
      settingsForm.setValues(settingsForm.initialValues)
    }
  }, [ edit ])

  useEffect(() => {
    const { order, orderBy } = tableForm.values.sort

    setFilteredList([ ...settingsList ].sort(getRowsComparator(order, orderBy)))
  }, [ tableForm.values.filters, settingsList ])

  const codeHelper = useMemo(() => {
    const { code } = settingsForm.values
    if (code.length > 0) {
      const codes = settingsList.filter((p) => p.code.toLowerCase().startsWith(code.toLowerCase())).map((p) => p.code).join(', ')
      return codes ? `Codes existants : ${codes}` : ''
    }
  }, [ settingsForm.values.code ])

  const setOrder = (order: Order) => tableForm.setFieldValue('sort.order', order)
  const setOrderBy = (orderBy: DataName) => tableForm.setFieldValue('sort.orderBy', orderBy)

  const createNewBooleanOptions = (): SegmentedButtonOption<boolean>[] => [ { value: false, label: 'Non' }, { value: true, label: 'Oui' } ]
  const personTypeOptions = enumToSegmentedButtonOptions(TypePersonneEnum)

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpenModal(true)}
      >
        Ajouter une famille annuaire
      </Button>
      <Form form={tableForm}>
        <LoadingOverlay isLoading={isFetchingSettingsList}>
          <TableCardContentContainer>
            <Table
              rows={filteredList}
              setRows={(rows) => setFilteredList(rows)}
              colHeaders={cols}
              limit={10}
              defaultOrder={{ order: 'asc', orderBy: 'code' }}
              sorting={{ setOrder, setOrderBy }}
            />
          </TableCardContentContainer>
        </LoadingOverlay>
      </Form>
      {
        openModal &&
          <Dialog
            open
            onClose={() => {setOpenModal(false); setEdit(null)}}
            fullWidth
            maxWidth="md"
          >
            <Form form={settingsForm}>
              <DialogDirectoryCategoryContainer>
                {`${edit ? 'Modifier la' : 'Ajouter une'} famille annuaire`}
                <CloseButton handleClose={() => {setOpenModal(false); setEdit(null)}} />
              </DialogDirectoryCategoryContainer>
              {
                <DialogContentContainer>
                  <DualContainer>
                    {
                      !edit &&
                        <Container>
                          <FormBoldTitle required>
                            Code
                          </FormBoldTitle>
                          <Field
                            component={TextField}
                            placeholder="Code"
                            name="code"
                            size="small"
                            helperText={codeHelper}
                          />
                        </Container>
                    }
                    <Container>
                      <FormBoldTitle required>
                        Libellé
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Libellé"
                        name="libelle"
                        size="small"
                      />
                    </Container>
                    <Container>
                      <FormBoldTitle required>
                        Type de personne
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={personTypeOptions}
                        selectedOption={settingsForm.values.typePersonne}
                        setSelectedOption={(v) => settingsForm.setFieldValue('typePersonne', v)}
                        smaller
                      />
                    </Container>
                  </DualContainer>
                  <DualContainer>
                    <Container>
                      <FormBoldTitle required>
                        Professionnel ?
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={createNewBooleanOptions()}
                        selectedOption={settingsForm.values.professionnel}
                        setSelectedOption={(v) => settingsForm.setFieldValue('professionnel', v)}
                        smaller
                      />
                    </Container>
                    <Container>
                      <FormBoldTitle required>
                        Visible ?
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={createNewBooleanOptions()}
                        selectedOption={settingsForm.values.visible}
                        setSelectedOption={(v) => settingsForm.setFieldValue('visible', v)}
                        smaller
                      />
                    </Container>
                  </DualContainer>
                </DialogContentContainer>
              }
              <DialogActionContainer>
                <FormButton
                  variant="outlined"
                  onClick={() => {setOpenModal(false); setEdit(null)}}
                >
                  Annuler
                </FormButton>
                <FormButton
                  variant="contained"
                  type="submit"
                  disabled={!edit && (!settingsForm.values.code || settingsList.some((v) => v.code === settingsForm.values.code))}
                >
                  Valider
                </FormButton>
              </DialogActionContainer>
            </Form>
          </Dialog>
      }
    </>
  )
}

export default SettingsDirectoryCategories
