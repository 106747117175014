/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ElementMediatheque } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface DraggrableDocument extends ElementMediatheque {
  y: number;
  x: number;
  height: number;
  width: number;
  edit: boolean;
}

interface DocumentList {
  [caseId: string]: DraggrableDocument[];
}

export interface LayoutState {
  openSummary: boolean;
  openMedia: boolean;
  documentList: DocumentList;
}

/* Redux slice -------------------------------------------------------------- */
const layoutSlice = createSlice(
  {
    name: 'layout',
    initialState: {
      openSummary: true,
      openMedia: false,
      documentList: {},
    } as LayoutState,
    reducers: {
      setOpenSummary: (state, { payload }: PayloadAction<boolean>) => {
        state.openSummary = payload
      },
      setOpenMedia: (state, { payload }: PayloadAction<boolean>) => {
        state.openMedia = payload
      },
      addDocument: (state, { payload }: PayloadAction<{caseId: string; document: ElementMediatheque; edit: boolean; width?: number; height?: number}>) => {
        const { caseId, document, height, width, edit } = payload

        if (!state.documentList[caseId]) {
          state.documentList[caseId] = []
        }
        if (!state.documentList[caseId].find((doc) => doc.id === document.id)) {
          state.documentList[caseId] = [ ...state.documentList[caseId], { ...document, x: 10, y: 10, edit, height: height || 670, width: width || 470 } ]
        }
      },
      removeDocument: (state, { payload }: PayloadAction<{caseId: string; id: string}>) => {
        const { caseId, id } = payload
        state.documentList[caseId] = state.documentList[caseId].filter((doc) => doc.id !== id)
      },
      changeDocumentPosition: (state, { payload }: PayloadAction<{caseId: string; id: string; x: number; y: number}>) => {
        const { caseId, id } = payload
        const index = state.documentList[caseId]?.findIndex((doc) => doc.id === id)

        if (index === -1) return state
        state.documentList[caseId] = state.documentList[caseId].map((doc, i) => index === i ? ({ ...doc, ...payload }) : doc)
      },
      changeDocumentSize: (state, { payload }: PayloadAction<{caseId: string; id: string; height: number; width: number}>) => {
        const { caseId, id } = payload
        const index = state.documentList[caseId]?.findIndex((doc) => doc.id === id)

        if (index === -1) return state
        state.documentList[caseId] = state.documentList[caseId].map((doc, i) => index === i ? ({ ...doc, ...payload }) : doc)
      },
      removeDocumentCaseList: (state, { payload }: PayloadAction<string>) => {
        state.documentList[payload] = []
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setOpenSummary,
  setOpenMedia,
  addDocument,
  removeDocument,
  changeDocumentPosition,
  changeDocumentSize,
  removeDocumentCaseList,
} = layoutSlice.actions

export default layoutSlice.reducer

export const getLayout = (state: RootState): LayoutState => {
  return state.layout
}
