/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useDeleteMediaTypeMutation,
  useGetMediaTypeListQuery,
  usePostMediaTypeMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import Table, { getRowsComparator } from 'components/Table/Table'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'components/Table/Table'
import type {
  CodeLabel,
  TypeMediaParametrageAnnuaireCreatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const tableSchema = Yup.object({
  filters: Yup.object({}).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type TableSchema = Yup.InferType<typeof tableSchema>

type TableForm = FormikContextType<TableSchema>

const settingsSchema = Yup.object().shape<Shape<TypeMediaParametrageAnnuaireCreatePayload>>({
  code: Yup.string().required('Le code est obligatoire'),
  libelle: Yup.string().required('Le libelle est obligatoire'),
})

type SettingsForm = FormikContextType<TypeMediaParametrageAnnuaireCreatePayload>

/* Styled components -------------------------------------------------------- */
const DialogMediaTypeContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

const DualContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Container = styled.div`
  width: 100%;
`

const TableCardContentContainer = styled.div`
  padding: 0px 15px 5px;
`

/* Component declaration ---------------------------------------------------- */
interface SettingsMediaTypesProps {}

const SettingsMediaTypes: React.FC<SettingsMediaTypesProps> = () => {
  const [ openModal, setOpenModal ] = useState<boolean>(false)
  const [ filteredList, setFilteredList ] = useState<CodeLabel[]>([])

  const [
    submitMediaType,
  ] = usePostMediaTypeMutation()
  const [
    deleteMediaType,
  ] = useDeleteMediaTypeMutation()
  const {
    currentData: settingsList = [],
    isFetching: isFetchingSettingsList,
  } = useGetMediaTypeListQuery()

  const onSubmit = (values: TypeMediaParametrageAnnuaireCreatePayload, { setSubmitting }: FormikHelpers<TypeMediaParametrageAnnuaireCreatePayload>) => {
    submitMediaType(values)
      .then((response) => {
        if (!isApiError(response)) {
          setOpenModal(false)
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onDelete = (code: string) => {
    deleteMediaType(code).catch(console.error)
  }

  const tableForm: TableForm = useForm<TableSchema>(
    {
      initialValues: {
        filters: {},
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: tableSchema,
    },
  )

  const settingsForm: SettingsForm = useForm<TypeMediaParametrageAnnuaireCreatePayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        code: '',
        libelle: '',
      },
      validationSchema: settingsSchema,
    },
  )

  const cols: ColumnHeader<CodeLabel>[] = [
    {
      id: 'code',
      label: 'Code',
    },
    {
      id: 'libelle',
      label: 'Libellé',
    },
    {
      id: 'code',
      label: 'Actions',
      removeFromExport: true,
      render: (value: string) => (
        <CustomIconButton
          Icon={Delete}
          variant="contained"
          color="error"
          label="Supprimer"
          onClick={() => onDelete(value)}
        />
      ),
    },
  ]

  useEffect(() => {
    const { order, orderBy } = tableForm.values.sort

    setFilteredList([ ...settingsList ].sort(getRowsComparator(order, orderBy)))
  }, [ tableForm.values.filters, settingsList ])

  const codeHelper = useMemo(() => {
    const { code } = settingsForm.values
    if (code.length > 0) {
      const codes = settingsList.filter((p) => p.code.toLowerCase().startsWith(code.toLowerCase())).map((p) => p.code).join(', ')
      return codes ? `Codes existants : ${codes}` : ''
    }
  }, [ settingsForm.values.code ])

  const setOrder = (order: Order) => tableForm.setFieldValue('sort.order', order)
  const setOrderBy = (orderBy: DataName) => tableForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpenModal(true)}
      >
        Ajouter un type de média
      </Button>
      <Form form={tableForm}>
        <LoadingOverlay isLoading={isFetchingSettingsList}>
          <TableCardContentContainer>
            <Table
              rows={filteredList}
              setRows={(rows) => setFilteredList(rows)}
              colHeaders={cols}
              limit={10}
              defaultOrder={{ order: 'asc', orderBy: 'code' }}
              sorting={{ setOrder, setOrderBy }}
            />
          </TableCardContentContainer>
        </LoadingOverlay>
      </Form>
      {
        openModal &&
          <Dialog
            open
            onClose={() => setOpenModal(false)}
            fullWidth
            maxWidth="md"
          >
            <Form form={settingsForm}>
              <DialogMediaTypeContainer>
                Ajouter un type de média
                <CloseButton handleClose={() => setOpenModal(false)} />
              </DialogMediaTypeContainer>
              {
                <DialogContentContainer>
                  <DualContainer>
                    <Container>
                      <FormBoldTitle required>
                        Code
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Code"
                        name="code"
                        size="small"
                        helperText={codeHelper}
                      />
                    </Container>
                    <Container>
                      <FormBoldTitle required>
                        Libellé
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Libellé"
                        name="libelle"
                        size="small"
                      />
                    </Container>
                  </DualContainer>
                </DialogContentContainer>
              }
              <DialogActionContainer>
                <FormButton
                  variant="outlined"
                  onClick={() => setOpenModal(false)}
                >
                  Annuler
                </FormButton>
                <FormButton
                  variant="contained"
                  type="submit"
                  disabled={!settingsForm.values.code || settingsList.some((v) => v.code === settingsForm.values.code)}
                >
                  Valider
                </FormButton>
              </DialogActionContainer>
            </Form>
          </Dialog>
      }
    </>
  )
}

export default SettingsMediaTypes
