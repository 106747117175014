/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePatchCaseInsuredMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { TextField } from 'formik-mui'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Feature } from 'geojson'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  Adresse,
  AdresseRequest,
  CoordoneesRequest,
  Personne,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const insuredSchema = Yup.object().shape<Shape<CoordoneesRequest>>({
  email: Yup.string().email("L'email est invalide").nullable(),
  telephonePortable: Yup.string().test(
    'personnal-phone',
    'Le portable est invalide',
    (item = '') => !item || (/^(06|07)/).test(item) && item.replaceAll(' ', '').length === 10,
  ).nullable(),
  telephoneFixe: Yup.string().test(
    'home-phone',
    'Le fixe est invalide',
    (item = '') => !item || ((/^(01|02|03|04|05|08|09)/).test(item) && item.replaceAll(' ', '').length === 10),
  ).nullable(),
  adresse: Yup.object().shape<Shape<AdresseRequest>>({
    adresse1: Yup.string().required("L'adresse est obligatoire"),
    adresse2: Yup.string().nullable(),
    adresse3: Yup.string().nullable(),
    ville: Yup.string().required('La ville est obligatoire'),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
  }).required(),
})

type InsuredForm = FormikContextType<CoordoneesRequest>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

const DualContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Container = styled.div`
  width: 100%;
`

const AddressSeparator = styled.div`
  height: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutInsuredModalProps {
  handleClose: () => void;
  caseId: string;
  insured: Personne;
}

const CaseLayoutInsuredModal: React.FC<CaseLayoutInsuredModalProps> = ({
  handleClose,
  caseId,
  insured,
}) => {
  const [
    submitEditInsured,
  ] = usePatchCaseInsuredMutation()

  const onSubmit = (values: CoordoneesRequest, { setSubmitting }: FormikHelpers<CoordoneesRequest>) => {
    submitEditInsured({ caseId, data: values })
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const formikForm: InsuredForm = useForm<CoordoneesRequest>(
    {
      onSubmit: onSubmit,
      initialValues: {
        adresse: {
          adresse1: '',
          adresse2: '',
          adresse3: '',
          codePostal: '',
          ville: '',
        },
        email: '',
        telephoneFixe: '',
        telephonePortable: '',
      },
      validationSchema: insuredSchema,
    },
  )

  useEffect(() => {
    if (insured) {
      formikForm.setValues(insured as CoordoneesRequest)
    }
  }, [ insured ])

  const onAddressChange = (newAddress: Feature) => {
    formikForm.setValues({
      ...formikForm.values,
      adresse: {
        ...formikForm.values.adresse,
        adresse1: newAddress.properties?.name as string,
        codePostal: newAddress.properties?.postcode as string,
        ville: newAddress.properties?.city as string,
      },
    })
  }

  const addressErrorProps = useMemo(() => {
    const addressTouched = (formikForm.touched.adresse as unknown as Adresse)?.adresse1 || false
    const addressError = (formikForm.errors.adresse as unknown as Adresse)?.adresse1 || ''

    return ({
      error: Boolean(addressTouched && addressError),
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }, [ formikForm.errors.adresse, formikForm.touched.adresse ])

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Modifier l'assuré
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        {
          <DialogContentContainer>
            <DualContainer>
              <Container>
                <FormBoldTitle required>
                  Prénom
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Prénom"
                  name="prenom"
                  size="small"
                  disabled
                />
              </Container>
              <Container>
                <FormBoldTitle required>
                  Nom
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Nom"
                  name="nom"
                  size="small"
                  disabled
                />
              </Container>
            </DualContainer>
            <DualContainer>
              <Container>
                <FormBoldTitle>
                  Téléphone portable
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Téléphone portable"
                  name="telephonePortable"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle>
                  Téléphone fixe
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Téléphone fixe"
                  name="telephoneFixe"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle>
                  Email
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Email"
                  name="email"
                  size="small"
                />
              </Container>
            </DualContainer>
            <FormBoldTitle required>
              Adresse
            </FormBoldTitle>
            <AddressAutocomplete
              placeholder="Adresse"
              value={formikForm.values.adresse?.adresse1 || ''}
              onValueChange={(newValue: string) => formikForm.setFieldValue('adresse.adresse1', newValue)}
              onAddressChange={(newValue) => onAddressChange(newValue)}
              size="small"
              {...addressErrorProps}
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse2"
              size="small"
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse3"
              size="small"
            />
            <DualContainer>
              <Container>
                <FormBoldTitle required>
                  Code postal
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Code postal"
                  name="adresse.codePostal"
                  size="small"
                />
              </Container>
              <Container>
                <FormBoldTitle required>
                  Ville
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ville"
                  name="adresse.ville"
                  size="small"
                />
              </Container>
            </DualContainer>
          </DialogContentContainer>
        }
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default CaseLayoutInsuredModal
