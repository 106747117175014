/* Type imports ------------------------------------------------------------- */
import type { Login } from 'store/slices/authSlice'

/* Local storage helper functions ------------------------------------------- */
const setLocalStorage = (pKey: string, pValue: string | null | undefined) => {
  /* Is the value valid ? */
  if (pValue === null || pValue === undefined) {
    /* No, it isn't valid */

    /* Does the entry exist ? */
    if (localStorage.getItem(pKey) === null) {
      /* Yes it does, remove it */
      localStorage.removeItem(pKey)
    }
    /* Else, do nothing */
  } else {
    /* Yes, it is valid */

    /* Set the new entry */
    localStorage.setItem(pKey, pValue)
  }
}

export const setAuthInLocalStorage = (pAuthInfo: Login | null): void => {
  /* Auth token */
  setLocalStorage(`iren_auth`, JSON.stringify(pAuthInfo))
}

export const resetAuthInLocalStorage = (): void => {
  localStorage.removeItem('iren_auth')
}

export const getAuthFromLocalStorage = (): Login | null => {
  const auth = JSON.parse(localStorage.getItem('iren_auth') || '{}') as Login

  if (!auth.token) {
    return null
  }

  return auth
}
