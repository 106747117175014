/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useIsDesktop,
  useIsMobile,
  useIsTabletPortrait,
} from 'helpers/hooks/useIsMedia'
import {
  useGetCaseDisasterQuery,
  useGetCaseInsuredQuery,
  useGetCaseMandatorListQuery,
  useGetCaseMissionQuery,
  useGetCaseStakeholderListQuery,
  useGetCaseWorkflowQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Collapse,
  Divider,
} from '@mui/material'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import CaseHistoryWorkflow from './CaseLayoutWorkflow/CaseLayoutWorkflow'
import CaseLayoutSummaryMandators from './CaseLayoutSummaryMandators'
import CaseLayoutSummaryDisaster from './CaseLayoutSummaryDisaster'
import CaseLayoutSummaryStakeholders from './CaseLayoutSummaryStakeholders'
import CaseLayoutSummaryMission from './CaseLayoutSummaryMission'

/* Type imports ------------------------------------------------------------- */
import type { Document } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface SummaryContainerProps {
  isOpen: boolean;
}

const SummaryContainer = styled.div<SummaryContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${(props) => props.isOpen ? '60px' : '0px'};
`

interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const BoldSeparator = styled(Divider)`
  margin: 5px 0px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryProps {
  documents: Document[];
  caseId: string;
}

const CaseLayoutSummary: React.FC<CaseLayoutSummaryProps> = ({
  documents,
  caseId,
}) => {
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const isTablet = useIsTabletPortrait()
  const [ expanded, setExpanded ] = useState<boolean>(false)

  const { currentData: workflow } = useGetCaseWorkflowQuery(caseId)
  const { currentData: disaster } = useGetCaseDisasterQuery(caseId)
  const { currentData: mission } = useGetCaseMissionQuery(caseId)
  const { currentData: insured } = useGetCaseInsuredQuery(caseId)
  const { currentData: mandatorList = []} = useGetCaseMandatorListQuery(caseId)
  const { currentData: stakeholderList = []} = useGetCaseStakeholderListQuery(caseId)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  return (
    <SummaryContainer isOpen={expanded}>
      {
        !workflow ?
          <CircularProgress /> :
          <CaseHistoryWorkflow workflow={workflow} />
      }
      <BoldSeparator />
      <CaseLayoutSummaryMandators mandatorList={mandatorList} />
      <BoldSeparator />
      {
        disaster &&
          <CaseLayoutSummaryDisaster
            disaster={disaster}
            caseId={caseId}
          />
      }
      <BoldSeparator />
      {
        insured &&
          <CaseLayoutSummaryStakeholders
            insured={insured}
            stakeholders={stakeholderList}
            caseId={caseId}
          />
      }
      <BoldSeparator />
      {
        mission &&
          <CaseLayoutSummaryMission
            mission={mission}
            caseId={caseId}
          />
      }
      <BoldSeparator />
      <BoldTitle
        onClick={handleExpandClick}
        isDropdown={isDesktop && documents.length > 0}
      >
        {`Documents ${isDesktop ? (`(${documents.length} disponible${documents.length > 1 ? 's' : ''})`) : ''}`}
        {isDesktop && documents.length > 0 && <DropdownArrow expanded={expanded.toString()} />}
      </BoldTitle>
      <Collapse
        in={isMobile || isTablet ? true : expanded}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {
            documents.map((document, index) => (
              <VignetteRendererWithFilename
                key={`${document.libelle}-${index}`}
                document={{ ...document, fileName: document.libelle }}
                onClick={() => window.open(document.url || '', '_blank', 'noreferrer')}
                width="min(100px, 100%)"
                height="120px"
              />
            ))
          }
        </GridContainer>
      </Collapse>
    </SummaryContainer>
  )
}

export default CaseLayoutSummary
