/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  CancelRounded,
  CheckCircleRounded,
  CircleRounded,
} from '@mui/icons-material'

/* Type imports ------------------------------------------------------------- */
import { WorkflowEtat } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Dot = styled.div`
  display: flex;
  align-items: center;
  margin-left: -30px;
  margin-right: 10px;

  svg {
    width: 18px;
  }
`

const NotDone = styled(CircleRounded)`
  color: ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutWorkflowDotProps {
  state: WorkflowEtat;
}

const CaseLayoutWorkflowDot: React.FC<CaseLayoutWorkflowDotProps> = ({ state }) => {

  const getDot = () => {
    switch (state) {
      case WorkflowEtat.EnAttente:
        return <CircleRounded color="warning" />
      case WorkflowEtat.Brouillon:
        return <CircleRounded color="primary" />
      case WorkflowEtat.NonFait:
        return <NotDone color="disabled" />
      case WorkflowEtat.Fait:
        return <CheckCircleRounded color="primary" />
      case WorkflowEtat.Annule:
        return <CancelRounded color="error" />
      case WorkflowEtat.Anomalie:
        return <CircleRounded color="warning" />
      default:
        return <CircleRounded color="primary" />
    }
  }

  return (
    <Dot>
      {getDot()}
    </Dot>
  )
}

export default CaseLayoutWorkflowDot
