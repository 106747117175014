/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import CaseLayoutWorkflowJalon from './CaseLayoutWorkflowJalon'
import CaseLayoutWorkflowDot from './CaseLayoutWorkflowDot'

/* Type imports ------------------------------------------------------------- */
import type { WorkflowEntreprise } from 'API/__generated__/Api'
import {
  WorkflowEtat,
  WorkflowJalon,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px !important;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

interface LineContainerProps {
  last?: boolean;
}

const LineContainer = styled.div<LineContainerProps>`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => props.theme.colors.grey};
  margin-left: 10px;
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '5px'};
  margin-bottom: ${(props) => props.last ? '5px' : '0px'};

  .MuiPaper-root {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutWorkflowProps {
  workflow: WorkflowEntreprise;
}

const CaseLayoutWorkflow: React.FC<CaseLayoutWorkflowProps> = ({ workflow }) => {
  const [ expanded, setExpanded ] = useState<boolean>(true)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  const findJalon = (jalon: WorkflowJalon) => {
    if (!workflow) return
    switch (jalon) {
      case WorkflowJalon.Missionnement:
        if (!workflow.jalonMissionnement) return
        return (
          {
            jalon: workflow.jalonMissionnement,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonMissionnement.type.libelle}
                state={workflow.jalonMissionnement?.etat}
                date={workflow.jalonCreation?.dateCreation}
              />
            ),
          }
        )
      case WorkflowJalon.DevisHorsPriseEnCharge:
        if (!workflow.jalonDevisHorsPriseEnCharge) return
        return (
          {
            jalon: workflow.jalonDevisHorsPriseEnCharge,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonDevisHorsPriseEnCharge.type.libelle}
                state={workflow.jalonDevisHorsPriseEnCharge?.etat}
                date={workflow.jalonDevisHorsPriseEnCharge?.dateCreation}
              />
            ),
          }
        )
      case WorkflowJalon.Intervention:
        if (!workflow.jalonIntervention) return
        return (
          {
            jalon: workflow.jalonIntervention,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonIntervention.type.libelle}
                state={workflow.jalonIntervention?.etat}
                date={workflow.jalonIntervention?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.PostIntervention:
        if (!workflow.jalonPostIntervention) return
        return (
          {
            jalon: workflow.jalonPostIntervention,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonPostIntervention.type.libelle}
                state={workflow.jalonPostIntervention?.etat}
                date={workflow.jalonPostIntervention?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.Contact:
        if (!workflow.jalonPriseDeContact) return
        return (
          {
            jalon: workflow.jalonPriseDeContact,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonPriseDeContact.type.libelle}
                state={workflow.jalonPriseDeContact?.etat}
                date={workflow.jalonPriseDeContact?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.RDVMetre:
        if (!workflow.jalonRDVMetre) return
        return (
          {
            jalon: workflow.jalonRDVMetre,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonRDVMetre.type.libelle}
                state={workflow.jalonRDVMetre?.etat}
                date={workflow.jalonRDVMetre?.dateRendezVous}
              />
            ),
          }
        )
      case WorkflowJalon.PostRdvMetre:
        if (!workflow.jalonPostRdvMetre) return
        return (
          {
            jalon: workflow.jalonPostRdvMetre,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonPostRdvMetre.type.libelle}
                state={workflow.jalonPostRdvMetre?.etat}
                date={workflow.jalonPostRdvMetre?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.Devis:
        if (!workflow.jalonDevis) return
        return (
          {
            jalon: workflow.jalonDevis,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonDevis.type.libelle}
                state={workflow.jalonDevis?.etat}
                date={workflow.jalonDevis?.dateAffichee}
              />
            ),
          }
        )
      case WorkflowJalon.RDVTravaux:
        if (!workflow.jalonRDVTravaux) return
        return (
          {
            jalon: workflow.jalonRDVTravaux,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonRDVTravaux.type.libelle}
                state={workflow.jalonRDVTravaux?.etat}
                date={workflow.jalonRDVTravaux?.rendezVousTravaux[0]?.dateDebut}
              />
            ),
          }
        )
      case WorkflowJalon.FinDeTravaux:
        if (!workflow.jalonFinDeTravaux) return
        return (
          {
            jalon: workflow.jalonFinDeTravaux,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonFinDeTravaux.type.libelle}
                state={workflow.jalonFinDeTravaux?.etat}
                date={workflow.jalonFinDeTravaux?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.FinDeTravauxRDF:
        if (!workflow.jalonFinDeTravauxRDF) return
        return (
          {
            jalon: workflow.jalonFinDeTravauxRDF,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonFinDeTravauxRDF.type.libelle}
                state={workflow.jalonFinDeTravauxRDF?.etat}
                date={workflow.jalonFinDeTravauxRDF?.dateValidation}
              />
            ),
          }
        )
      case WorkflowJalon.CompteRenduVisite:
        if (!workflow.jalonCompteRendu) return
        return (
          {
            jalon: workflow.jalonCompteRendu,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonCompteRendu.type.libelle}
                state={workflow.jalonCompteRendu?.etat}
                date={workflow.jalonCompteRendu?.date}
              />
            ),
          }
        )
      case WorkflowJalon.CompteRenduRDF:
        if (!workflow.jalonCompteRendu) return
        return (
          {
            jalon: workflow.jalonCompteRendu,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonCompteRendu.type.libelle}
                state={workflow.jalonCompteRendu?.etat}
                date={workflow.jalonCompteRendu?.date}
              />
            ),
          }
        )
      case WorkflowJalon.Facture:
        if (!workflow.jalonFacture) return
        return (
          {
            jalon: workflow.jalonFacture,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonFacture.type.libelle}
                state={workflow.jalonFacture?.etat}
                date={workflow.jalonFacture?.dateAffichee}
              />
            ),
          }
        )
      case WorkflowJalon.DossierTermine:
        if (!workflow.jalonDossierTermine) return
        return (
          {
            jalon: workflow.jalonDossierTermine,
            render: (
              <CaseLayoutWorkflowJalon
                label={workflow.jalonDossierTermine.type.libelle}
                state={workflow.jalonDossierTermine?.etat}
                date={workflow.jalonDossierTermine?.date}
              />
            ),
          }
        )
      default:
        break
    }
  }

  const order = useMemo(() => workflow.order.filter((val) => val !== WorkflowJalon.Anomalies), [ workflow ])

  return (
    <Container>
      <BoldTitle
        onClick={handleExpandClick}
        isDropdown
      >
        Workflow
        <DropDownArrow open={expanded} />
      </BoldTitle>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {
          order.map((order, index, { length }) => (
            <LineContainer
              key={order}
              last={index === length - 1}
            >
              <CaseLayoutWorkflowDot state={findJalon(order)?.jalon?.etat || WorkflowEtat.NonFait} />
              {findJalon(order)?.render}
            </LineContainer>
          ))
        }
      </Collapse>
    </Container>
  )
}

export default CaseLayoutWorkflow
