/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Tab,
  Tabs,
} from '@mui/material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import SettingsSalutations from './SettingsComponents/SettingsSalutations'
import SettingsTitles from './SettingsComponents/SettingsTitles'
import SettingsMediaTypes from './SettingsComponents/SettingsMediaTypes'
import SettingsDirectoryCategories from './SettingsComponents/SettingsDirectoryCategories'
import SettingsExchangesAccounts from './SettingsComponents/SettingsExchangeAccounts'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'

/* Type declarations -------------------------------------------------------- */
interface Settings {
  search: string;
}

const settingsSchema = Yup.object().shape({
  search: Yup.string().nullable(),
})

type SettingsForm = FormikContextType<Settings>

/* Styled components -------------------------------------------------------- */
const CardTitle = styled(Card)`
  padding: 15px 15px 0px;
  margin-bottom: 20px;
`

const AutoCompleteContainer = styled.div`
  width: 50%;
`

const TabsContainer = styled(Tabs)`
  .MuiTabs-indicator {
    width: 3px;
  }
`

const TabContainer = styled(Tab)`
  place-items: start;
  min-height: 35px;
`

interface LayoutContainerProps {
  top: number;
}

const LayoutContainer = styled.div<LayoutContainerProps>`
  @media ${(props) => props.theme.media.desktop}, ${(props) => props.theme.media.tablet} {
    max-height: ${(props) => `calc(100vh - ${props.top}px - 10px)`};
    overflow-y: scroll;
  }
`

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`

const TabsCard = styled(Card)`
  width: 200px;
`

const CategorieContainer = styled.div`
  width: calc(100% - 220px);
`

interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

/* Component declaration ---------------------------------------------------- */
interface SettingsPageProps {}

const SettingsPage: React.FC<SettingsPageProps> = () => {
  const [ offsetTop, setOffsetTop ] = useState<number>(200)
  const [ tabValue, setTabValue ] = useState<number>(0)
  const [ tabs, setTabs ] = useState([
    {
      label: 'Annuaire',
      categories: [
        {
          label: 'Formules de politesse',
          expanded: false,
          component: <SettingsSalutations />,
        },
        {
          label: 'Intitulés',
          expanded: false,
          component: <SettingsTitles />,
        },
        {
          label: 'Types de média',
          expanded: false,
          component: <SettingsMediaTypes />,
        },
        {
          label: 'Familles/Fonction',
          expanded: false,
          component: <SettingsDirectoryCategories />,
        },
        {
          label: 'Comptes Exchange à synchroniser',
          expanded: false,
          component: <SettingsExchangesAccounts />,
        },
      ],
    },
    {
      label: 'Bordereaux',
      categories: [],
    },
    {
      label: 'Comptabilité',
      categories: [],
    },
    {
      label: 'Divers',
      categories: [],
    },
    {
      label: 'Dossier',
      categories: [],
    },
    {
      label: 'Evénement',
      categories: [],
    },
    {
      label: 'Echange internes',
      categories: [],
    },
    {
      label: 'Facture',
      categories: [],
    },
  ])

  useEffect(() => {
    const calculateOffsetTop = () => {
      const element = document.getElementById('settings-layout-offset')
      if (element) {
        setOffsetTop(element.offsetTop)
      }
    }

    calculateOffsetTop()

    window.addEventListener('resize', calculateOffsetTop)

    return () => {
      window.removeEventListener('resize', calculateOffsetTop)
    }
  }, [])

  const formikForm: SettingsForm = useForm<Settings>(
    {
      initialValues: {
        search: '',
      },
      validationSchema: settingsSchema,
    },
  )

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
  }

  const onChangeTabExpanded = (categorieIndex: number, newValue: boolean) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab, tabIndex) =>
        tabIndex === tabValue ?
          {
            ...tab,
            categories: tab.categories.map((category, catIndex) => catIndex === categorieIndex ? { ...category, expanded: newValue } : category),
          } :
          tab,
      ),
    )
  }

  const onReduceAll = () => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) =>
        ({ ...tab, categories: tab.categories.map((category) => ({ ...category, expanded: false })) }),
      ),
    )
  }

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.categories.some((c) => c.label === formikForm.values.search))
    if (index !== -1) {
      setTabValue(index)
    }

    setTabs((prevTabs) =>
      prevTabs.map((tab, tabIndex) =>
        tabIndex === index ?
          {
            ...tab,
            categories: tab.categories.map((category) => ({ ...category, expanded: category.label === formikForm.values.search })),
          } :
          tab,
      ),
    )
  }, [ formikForm.values.search ])

  return (
    <PageContainer>
      <CardTitle>
        <Form form={formikForm}>
          <LargeTitle>
            Paramètres
            <AutoCompleteContainer>
              <AutocompleteField
                name="search"
                options={tabs.flatMap((v) => v.categories).map(((v) => v.label))}
                size="small"
                placeholder="Rechercher un paramètre"
              />
            </AutoCompleteContainer>
            <Button
              variant="contained"
              onClick={onReduceAll}
            >
              Tout réduire
            </Button>
          </LargeTitle>
        </Form>
      </CardTitle>
      <LayoutContainer
        id="settings-layout-offset"
        top={offsetTop}
      >
        <FlexContainer>
          <TabsCard>
            <CardContent>
              <TabsContainer
                value={(tabValue === -1 || tabValue >= tabs.length) ? false : tabValue}
                orientation="vertical"
                variant="scrollable"
              >
                {
                  tabs.map((tab, index) => (
                    <TabContainer
                      key={`${tab.label}-${index}`}
                      onClick={() => handleTabChange(index)}
                      label={tab.label}
                      color="info"
                    />
                  ))
                }
              </TabsContainer>
            </CardContent>
          </TabsCard>
          <CategorieContainer>
            {
              tabs[tabValue].categories.map((value, index) => (
                <>
                  <BoldTitle
                    key={`${value.label}-${index}`}
                    onClick={() => onChangeTabExpanded(index, !value.expanded)}
                    isDropdown
                  >
                    {value.label}
                    <DropdownArrow expanded={String(value.expanded)} />
                  </BoldTitle>
                  <Collapse
                    in={value.expanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    {value.component}
                  </Collapse>
                </>
              ))
            }
          </CategorieContainer>
        </FlexContainer>
        <Footer />
      </LayoutContainer>
    </PageContainer>
  )
}

export default SettingsPage
