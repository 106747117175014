/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import { getUuid } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material'
import { MoreVertRounded } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import MeasureArticleReadOnlyLines from './MeasureArticleReadOnlyLines'
import MeasureArticleLine from './MeasureArticleLine'
import MeasureDecoteReadOnlyLines from './MeasureDecoteReadOnlyLines'
import MeasureDecoteLine from './MeasureDecoteLine'

/* Type imports ------------------------------------------------------------- */
import type {
  Article,
  Bordereau,
  CodeLabel,
  Decote,
  Surface,
} from 'API/__generated__/Api'
import { TypeSurface } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 20px;
  gap: 5px;
  padding: 5px;
  align-items: center;
`

const IconButton = styled(Button)`
  align-self: center;
  min-width: auto;
  width: 20px !important;
`

/* Component declaration ---------------------------------------------------- */
interface MeasureAreaLineProps {
  lines: Surface[];
  setFieldValue: (field: string, value: string | boolean | number | Article | Article[] | Surface | Surface[] | Decote | Decote[]) => void;
  index: number;
  lineName: string;
  linesName: string;
  addNewArticleInArea: () => void;
  addNewDecoteInArea: () => void;
  readOnly: boolean;
  measureUnitList: CodeLabel[];
  articleBordereauList: Bordereau[];
}

const MeasureAreaLine: React.FC<MeasureAreaLineProps> = ({
  lines,
  setFieldValue,
  index,
  lineName,
  linesName,
  addNewArticleInArea,
  addNewDecoteInArea,
  readOnly,
  measureUnitList,
  articleBordereauList,
}) => {
  const [ anchorLineMenu, setAnchorLineMenu ] = useState<null | SVGElement>(null)

  const handleLineMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    event.stopPropagation()
    setAnchorLineMenu(event.currentTarget)
  }

  const handleLineMenuClose = (): void => {
    setAnchorLineMenu(null)
  }

  const onDuplicateClick = (keep: boolean = false) => {
    if (!lines?.length) return
    const newArray = [ ...lines ]
    const duplicateItem = { ...lines[index], id: getUuid() }

    if (!keep) {
      duplicateItem.articles = []
      duplicateItem.decotes = []
    } else {
      duplicateItem.articles = duplicateItem.articles?.map((v) => ({ ...v, id: getUuid() })) || []
      duplicateItem.decotes = duplicateItem.decotes?.map((v) => ({ ...v, id: getUuid() })) || []
    }

    newArray.splice(index + 1, 0, duplicateItem)
    setFieldValue(linesName, newArray)
    handleLineMenuClose()
  }

  const onDeleteClick = () => {
    if (!lines?.length) return
    const newArray = [ ...lines ]

    newArray.splice(index, 1)
    setFieldValue(linesName, newArray)
    handleLineMenuClose()
  }

  const surfaceTypeOptions = enumToSegmentedButtonOptions(TypeSurface)

  const onCalculateRoom = (surfaceIndex: number, valueType: 'longueur' | 'largeur', value: number | string) => {
    const realValue = typeof value === 'string' ? parseFloat(value || '0') : value

    setFieldValue(`${lineName}.${valueType}`, value)
    if (!lines[surfaceIndex]) return
    const { longueur = 0, largeur = 0 } = lines[surfaceIndex]
    if (valueType === 'longueur') {
      const area = realValue * (largeur || 0)
      setFieldValue(`${lineName}.superficie`, area)
    } else {
      const area = realValue * (longueur || 0)
      setFieldValue(`${lineName}.superficie`, area)
    }
  }

  return (
    <>
      <GridContainer>
        <Field
          component={TextField}
          name={`${lineName}.libelle`}
          size="small"
          placeholder="Libellé"
        />
        <SegmentedButtons
          options={surfaceTypeOptions}
          selectedOption={lines?.[index].type}
          setSelectedOption={(v) => setFieldValue(`${lineName}.type`, v)}
          smaller
        />
        <NumberField
          name={`${lineName}.longueur`}
          onChange={(e) => onCalculateRoom(index, 'longueur', e.target.value)}
          size="small"
        />
        <NumberField
          name={`${lineName}.largeur`}
          onChange={(e) => onCalculateRoom(index, 'largeur', e.target.value)}
          size="small"
        />
        <NumberField
          name={`${lineName}.superficie`}
          onChange={(e) => setFieldValue(`${lineName}.superficie`, e.target.value)}
          size="small"
        />
        <IconButton
          variant="text"
          size="small"
        >
          <MoreVertRounded
            fontSize="large"
            onClick={handleLineMenuClick}
          />
        </IconButton>
        <Menu
          anchorEl={anchorLineMenu}
          open={Boolean(anchorLineMenu)}
          onClose={handleLineMenuClose}
          onClick={(e): void => e.stopPropagation()}
        >
          <MenuItem onClick={addNewDecoteInArea}>
            Ajouter une décote
          </MenuItem>
          <MenuItem onClick={addNewArticleInArea}>
            Ajouter un article
          </MenuItem>
          <MenuItem onClick={() => onDuplicateClick(false)}>
            Dupliquer
          </MenuItem>
          <MenuItem onClick={() => onDuplicateClick(true)}>
            Dupliquer avec articles et décotes
          </MenuItem>
          <MenuItem onClick={onDeleteClick}>
            Supprimer
          </MenuItem>
        </Menu>
      </GridContainer>
      {
        readOnly ?
          <MeasureDecoteReadOnlyLines lines={lines[index].decotes || []} /> :
          lines[index].decotes?.map((item, decoteIndex) => (
            <MeasureDecoteLine
              key={`${lineName}.decotes.[${decoteIndex}]`}
              index={decoteIndex}
              lines={lines[index].decotes || []}
              setFieldValue={setFieldValue}
              lineName={`${lineName}.decotes.[${decoteIndex}]`}
              linesName={`${lineName}.decotes`}
            />
          ))
      }
      {
        readOnly ?
          <MeasureArticleReadOnlyLines
            lines={lines[index].articles || []}
            measureUnitList={measureUnitList}
          /> :
          lines[index].articles?.map((item, articleIndex) => (
            <MeasureArticleLine
              key={`${lineName}.articles.[${articleIndex}]`}
              index={articleIndex}
              lines={lines[index].articles || []}
              setFieldValue={setFieldValue}
              measureUnitList={measureUnitList}
              articleBordereauList={articleBordereauList}
              lineName={`${lineName}.articles.[${articleIndex}]`}
              linesName={`${lineName}.articles`}
              isArea
            />
          ))
      }
    </>
  )
}

export default MeasureAreaLine
