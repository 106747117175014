/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Tooltip } from '@mui/material'
import CustomIconButtonContainer from './CustomIconButtonContainer'

/* Type imports ------------------------------------------------------------- */
import type {
  ButtonProps,
  TooltipOwnerState, 
} from '@mui/material'
import type { SvgIconComponent } from '@mui/icons-material'
import type { IconButtonSize } from './CustomIconButtonContainer'

/* Component declaration ---------------------------------------------------- */
interface CustomIconButtonProps extends ButtonProps {
  Icon: SvgIconComponent;
  label?: string;
  customsize?: IconButtonSize;
  labelPlacement?: TooltipOwnerState['placement'];
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({ Icon, label, labelPlacement = 'top', ...rest }) => {
  return (
    <Tooltip
      arrow
      placement={labelPlacement}
      title={label}
    >
      <CustomIconButtonContainer
        {...rest}
        ref={rest.ref}
      >
        <Icon />
      </CustomIconButtonContainer>
    </Tooltip>
  )
}

export default CustomIconButton
