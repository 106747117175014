/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useNavigate,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import {
  Tab,
  Tabs,
} from '@mui/material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import Footer from 'layouts/Footer/Footer'

/* Internal variable declarations ------------------------------------------- */
const tabs = [
  {
    value: 'dossiers',
    label: 'Tous les dossiers',
    disabled: false,
  },
  {
    value: 'nouveaux-dossiers',
    label: 'Nouveaux dossiers',
    disabled: true,
  },
  {
    value: 'metres',
    label: 'Rdv métré',
    disabled: true,
  },
  {
    value: 'devis',
    label: 'Devis',
    disabled: false,
  },
  {
    value: 'commandes',
    label: 'Commandes',
    disabled: false,
  },
  {
    value: 'travaux',
    label: 'Rdv travaux',
    disabled: true,
  },
  {
    value: 'factures',
    label: 'Factures',
    disabled: false,
  },
]

/* Styled components -------------------------------------------------------- */
const TabsContainer = styled(Tabs)`
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-bottom: 5px;

  .MuiTabs-scrollButtons {
    width: 15px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DashboardLayoutProps {}

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
  const navigate = useNavigate()
  const [ tabValue, setTabValue ] = useState<number | boolean>(0)

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
    navigate(tabs[newValue].value)
  }

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.value) !== -1)
    setTabValue(newTabValue)
  }, [ location.pathname ])

  return (
    <PageContainer>
      <TabsContainer
        value={tabValue === -1 ? false : tabValue}
        variant="scrollable"
      >
        {
          tabs.map((tab, index) => (
            <Tab
              key={`${tab.value}-${index}`}
              onClick={() => handleTabChange(index)}
              label={tab.label}
              color="info"
              disabled={tab.disabled}
            />
          ))
        }
      </TabsContainer>
      <Outlet />
      <Footer />
    </PageContainer>
  )
}

export default DashboardLayout
