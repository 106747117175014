/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { getRowsComparator } from 'components/Table/Table'
import { useGetCaseMeasureListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CaseMeasureTable from './CaseMeasureComponents/CaseMeasureTable'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  DataName,
  Order,
} from 'components/Table/Table'
import type { MetreDossier } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const measureSchema = Yup.object({
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type MeasureSchema = Yup.InferType<typeof measureSchema>

type MeasureForm = FormikContextType<MeasureSchema>

/* Component declaration ---------------------------------------------------- */
interface CaseMeasurePageProps {}

const CaseMeasurePage: React.FC<CaseMeasurePageProps> = () => {
  const { caseId = '' } = useParams<{ caseId: string }>()
  const navigate = useNavigate()
  const [ filteredList, setFilteredList ] = useState<MetreDossier[]>([])

  const {
    currentData: measureList = [],
    isFetching: isFetchingMeasureList,
  } = useGetCaseMeasureListQuery(caseId)

  const formikForm: MeasureForm = useForm<MeasureSchema>(
    {
      initialValues: {
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: measureSchema,
    },
  )

  useEffect(() => {
    const { order, orderBy } = formikForm.values.sort

    setFilteredList([ ...measureList ].sort(getRowsComparator(order, orderBy)))
  }, [ formikForm.values.sort, measureList ])

  const onNewClick = () => {
    navigate('nouveau')
  }

  const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <div>
      <LargeTitle>
        Métrés
        <Button
          variant="contained"
          onClick={onNewClick}
        >
          Nouveau métré
        </Button>
      </LargeTitle>
      <Form form={formikForm}>
        <CaseMeasureTable
          filteredList={filteredList}
          isFetchingList={isFetchingMeasureList}
          setFilteredList={setFilteredList}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Form>
    </div>
  )
}

export default CaseMeasurePage
