/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  addCase,
  getCaseList,
  removeCase,
} from 'store/slices/caseListSlice'
import { caseTabs } from 'helpers/caseUtils'

/* Component imports -------------------------------------------------------- */
import {
  Divider,
  Tab,
  Tabs,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import RouteTitle from 'router/RouteTitle'
import { removeDocumentCaseList } from 'store/slices/layoutSlice'

/* Internal variable declarations ------------------------------------------- */
const tabs: {label: string; path: string; isDropdown?: boolean}[] = [
  {
    label: 'Dashboard',
    path: 'dashboard',
  },
  {
    label: 'Échanges internes',
    path: 'echanges-internes',
  },
  {
    label: 'Tâches',
    path: 'taches',
  },
  {
    label: 'Planning',
    path: 'planning',
  },
  {
    label: 'Comptabilité',
    path: 'comptabilite',
    isDropdown: true,
  },
  {
    label: 'Paramètres',
    path: 'parametres',
    isDropdown: true,
  },
]

/* Styled components -------------------------------------------------------- */
const TabsContainer = styled(Tabs)`
  .MuiTabs-indicator {
    width: 8px;
    background-color: ${(props) => props.theme.palette.info.main};
  }

  .Mui-selected {
    color: ${(props) => props.theme.palette.info.main} !important;
  }

  .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-vertical {
    height: 20px;
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    max-height: calc(100vh - 300px);
  }
`

const CaseTabsConatiner = styled(TabsContainer)`
  max-height: calc(100vh - 510px);

  @media ${(props) => props.theme.media.mobile.portrait} {
    max-height: calc(100vh - 440px);
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    max-height: calc(100vh - 300px);
  }
`

const TabContainer = styled(Tab)`
  color: ${(props) => props.theme.colors.main};
  place-items: start;
  min-height: 35px;
`

const OpenedCase = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 10px 0px;

  @media ${(props) => props.theme.media.mobile.landscape} {
    padding: 0px 10px 0px;
  }
`

const Label = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LabelName = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
`

const LabelCaseId = styled.div`
  display: flex;
  gap: 5px;
`

const Uppercase = styled.div`
  text-transform: uppercase;
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutNavTabsProps {
  closeDrawer: () => void;
}

const MainLayoutNavTabs: React.FC<MainLayoutNavTabsProps> = ({ closeDrawer }) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ tabValue, setTabValue ] = useState<number | false>(false)
  const [ title, setTitle ] = useState<string>('')
  const caseList = useAppSelector(getCaseList)

  const findCaseTabLabel = (tab: string) => caseTabs.find((l) => l.path === tab)?.label || tab

  useEffect(() => {
    const caseRegex = /^\/dossiers\/(\d{4}-[A-Z]{2}-\d{8})\/(.+)$/
    let caseListIndex = -1
    if (caseRegex.test(location.pathname)) {
      const matches = location.pathname.match(caseRegex)
      const tab = matches?.[2] || ''
      caseListIndex = caseList.findIndex((c) => c.tab === tab && c.caseId === caseId)

      if (caseListIndex === -1) {
        dispatch(addCase({ caseId, tab, name: '' }))
        caseListIndex = caseList.findIndex((c) => c.caseId === caseId)
      }
      setTabValue(tabs.length + (caseListIndex === -1 ? caseList.length : caseListIndex))
      setTitle(`${findCaseTabLabel(tab)} ${caseId}`)
    } else {
      const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
      setTabValue(newTabValue)
      if (tabs[newTabValue]?.label) {
        setTitle(`${tabs[newTabValue].label} ${caseId}`)
      }
    }
  }, [ location.pathname ])

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
    navigate(`/${tabs[newValue].path}`)
    closeDrawer()
  }

  const handleCaseTabChange = (newValue: number): void => {
    setTabValue(tabs.length + newValue)
    navigate(`/dossiers/${caseList[newValue].caseId}/${caseList[newValue].tab}`)
    closeDrawer()
  }

  const closeTab = (id: string) => (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation()
    const indexId = caseList.findIndex((c) => c.caseId === id)
    const indexCaseId = caseList.findIndex((c) => c.caseId === caseId)
    if (id === caseId) {
      if (caseList.length === 1) {
        navigate('/dossiers')
      } else if (indexId === 0) {
        setTabValue(tabs.length + 1)
        navigate(`/dossiers/${caseList[1].caseId}/${caseList[1].tab}`)
      } else {
        setTabValue(tabs.length + indexId - 1)
        navigate(`/dossiers/${caseList[indexId - 1].caseId}/${caseList[indexId - 1].tab}`)
      }
    } else if (indexId < indexCaseId) {
      setTabValue((tabValue || 0) - 1)
    }
    dispatch(removeCase(id))
    dispatch(removeDocumentCaseList(id))
  }

  return (
    <>
      <RouteTitle title={title} />
      <TabsContainer
        value={(tabValue === -1 || tabValue === false || tabValue >= tabs.length) ? false : tabValue}
        orientation="vertical"
        variant="scrollable"
      >
        {
          tabs.map((tab, index) => (
            <TabContainer
              key={`${tab.path}-${index}`}
              onClick={() => handleTabChange(index)}
              label={tab.label}
              color="info"
            />
          ))
        }
      </TabsContainer>
      {
        caseList.length !== 0 &&
          <React.Fragment>
            <Divider className="side-divider" />
            <OpenedCase>
              Dossiers ouverts
            </OpenedCase>
            <CaseTabsConatiner
              value={(tabValue === -1 || tabValue === false || tabValue < tabs.length - 1) ? false : tabValue - tabs.length}
              orientation="vertical"
              variant="scrollable"
            >
              {
                caseList.map((value, index) => (
                  <TabContainer
                    key={`${value.caseId}-${index}`}
                    onClick={() => handleCaseTabChange(index)}
                    label={
                      <Label>
                        <LabelName>
                          <b>
                            {value.caseId}
                          </b>
                          <LabelCaseId>
                            {
                              value.name &&
                                <Uppercase>
                                  {`${value.name} - `}
                                </Uppercase>
                            }
                            {`${findCaseTabLabel(value.tab)}`}
                          </LabelCaseId>
                        </LabelName>
                        <Close onClick={closeTab(value.caseId)} />
                      </Label>
                    }
                    color="info"
                  />
                ))
              }
            </CaseTabsConatiner>
          </React.Fragment>
      }
    </>
  )
}

export default MainLayoutNavTabs
