/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import type { DraggrableDocument } from 'store/slices/layoutSlice'
import {
  changeDocumentPosition,
  changeDocumentSize,
  removeDocument,
} from 'store/slices/layoutSlice'

/* Component imports -------------------------------------------------------- */
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import Moveable from 'react-moveable'
import CaseLayoutMediaFileDisplay from './CaseLayoutMediaFileDisplay'

/* Styled components -------------------------------------------------------- */
const Box = styled.div`
  cursor: move;
  position: absolute;
  background-color: ${(props) => props.theme.colors.main};;
  z-index: 10;
  display: flex;
  flex-direction: column;
`

const NavBar = styled.div`
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.main};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 7px 0px 5px 7px;
  overflow-wrap: anywhere;

  svg {
    color: ${(props) => props.theme.colors.main};
  }
`

interface FrameContainerProps {
  height: number;
}

const FrameContainer = styled.div<FrameContainerProps>`
  height: ${(props) => `calc(100% - ${props.height}px)`};
  width: 100%;
  position: relative;
`

const ResizeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutDraggableDocumentProps {
  doc: DraggrableDocument;
}

const CaseLayoutDraggableDocument: React.FC<CaseLayoutDraggableDocumentProps> = ({ doc }) => {
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ isMoving, setIsMoving ] = useState<boolean>(false)
  const [ navBarHeight, setNavBarHeight ] = useState<number>(40)
  const boxRef = useRef<HTMLDivElement | null>(null)
  const navBarRef = useRef<HTMLDivElement | null>(null)

  const onCloseDrag = () => {
    dispatch(removeDocument({ caseId, id: doc.id || '' }))
  }

  const onRenderEnd = ({ width, height, ...props }: {width: number; height: number; top: number; left: number}) => {
    dispatch(changeDocumentPosition({ caseId, id: doc.id || '', x: props.left, y: props.top }))
    dispatch(changeDocumentSize({ caseId, id: doc.id || '', width, height }))
    setIsMoving(false)
  }

  const toPx = (value: number) => `${value}px`

  useEffect(() => {
    if (navBarRef.current) {
      setNavBarHeight(navBarRef.current.offsetHeight)
    }
  }, [ navBarRef.current?.offsetHeight ])

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.style.width = toPx(doc.width)
      boxRef.current.style.height = toPx(doc.height)
      boxRef.current.style.top = toPx(doc.y)
      boxRef.current.style.left = toPx(doc.x)
    }
  }, [])

  return (
    <React.Fragment>
      <Box ref={boxRef}>
        <NavBar ref={navBarRef}>
          {doc.libelle}
          <IconButton onClick={onCloseDrag}>
            <Close />
          </IconButton>
        </NavBar>
        <FrameContainer height={navBarHeight}>
          {isMoving && <ResizeOverlay />}
          <CaseLayoutMediaFileDisplay
            edit={doc.edit}
            document={doc}
          />
        </FrameContainer>
      </Box>
      <Moveable
        target={boxRef}
        origin={false}
        draggable
        resizable
        onRender={(e) => e.target.style.cssText += e.cssText}
        onRenderEnd={(e) => onRenderEnd(e.moveable.getRect())}
        onResizeStart={() => setIsMoving(true)}
        onDragStart={() => setIsMoving(true)}
      />
    </React.Fragment>
  )
}

export default CaseLayoutDraggableDocument
