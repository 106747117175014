/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'

/* Type imports ------------------------------------------------------------- */
import type {
  ImageMetre,
  PieceJointe,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ImageContainer = styled.div`
  width: min(120px, 100%);
  position: relative;
`

const DeleteButtonContainer = styled(IconButton)`
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 10;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.225);
`

/* Component declaration ---------------------------------------------------- */
interface MeasureThumbnailProps {
  image?: ImageMetre;
  file?: File;
  onDeleteClick?: () => void;
}

const MeasureThumbnail: React.FC<MeasureThumbnailProps> = ({
  image,
  file,
  onDeleteClick,
}) => {
  const document: PieceJointe | null = useMemo(() => {
    if (file) {
      return { ...file, fileName: file.name, libelle: file.name }
    }
    if (image) {
      return { ...image, fileName: image.fileName || '', libelle: image.libelle || '' }
    }
    return null
  }, [ image, file ])

  if (!document) return null

  return (
    <ImageContainer>
      {
        onDeleteClick &&
          <DeleteButtonContainer
            onClick={onDeleteClick}
            size="small"
          >
            <Delete />
          </DeleteButtonContainer>
      }
      {
        <VignetteRendererWithFilename
          document={document}
          file={file}
          onClick={() => null}
          width="min(120px, 100%)"
          height="150px"
        />
      }
    </ImageContainer>
  )
}

export default MeasureThumbnail
