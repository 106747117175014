/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import DOMPurify from 'dompurify'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import Table, { getRowsComparator } from 'components/Table/Table'
import {
  useGetCaseHistoryQuery,
  useGetCaseWorkflowQuery,
} from 'store/api'
import { getHistoryTypeColor } from 'helpers/caseUtils'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CircularProgress,
  Collapse,
} from '@mui/material'
import {
  FilePresent,
  KeyboardArrowDownRounded,
  Search,
} from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import TableFilterList from 'components/Table/TableFilterList'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import CaseHistoryFilters from './CaseHistoryComponents/CaseHistoryFilters'
import CaseHistoryWorkflow from './CaseHistoryComponents/CaseHistoryWorkflow'
import CaseHistoryNewEventModal from './CaseHistoryComponents/CaseHistoryNewEventModal'
import CaseHistoryMobileTableCard from './CaseHistoryComponents/CaseHistoryMobileTableCard'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'components/Table/Table'
import type {
  HistoriqueSimplifie,
  PieceJointeSimplifie,
  TypeSuiviEnumLabel,
} from 'API/__generated__/Api'
import { IconButtonSize } from 'components/IconButtons/CustomIconButton/CustomIconButtonContainer'

/* Type declarations -------------------------------------------------------- */
const historySchema = Yup.object({
  filters: Yup.object({
    types: Yup.array(Yup.string().required()).required(),
    roleEtat: Yup.array(Yup.string().required()).required(),
    destinataire: Yup.array(Yup.string().required()).required(),
  }).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type HistorySchema = Yup.InferType<typeof historySchema>

type HistoryForm = FormikContextType<HistorySchema>

/* Styled components -------------------------------------------------------- */
const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 15px 5px;

  .status-chip {
    width: 100%;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: block;
    min-height: 200px;
  }
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .status-chip {
    font-size: .8rem;
  }
`

const ActionContainer = styled.div`
  display: flex;
`

const Chip = styled(ColoredSquareChip)`
  height: 36px;
  border-radius: 10px;
  cursor: pointer;

  transition: filter 0.2s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    filter: brightness(0.92);
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.95);
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseHistoryPageProps {}

const CaseHistoryPage: React.FC<CaseHistoryPageProps> = () => {
  const { caseId = '' } = useParams<{ caseId: string }>()
  const [ filteredList, setFilteredList ] = useState<HistoriqueSimplifie[]>([])
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ openModal, setOpenModal ] = useState<boolean>(false)
  const [ filters, setFilters ] = useState<{types: TypeSuiviEnumLabel[]; roleEtat: string[]; destinataire: string[]}>({ types: [], roleEtat: [], destinataire: []})

  const { currentData: workflow } = useGetCaseWorkflowQuery(caseId)
  const {
    currentData: history = [],
    isFetching: isFetchingCaseHistory,
  } = useGetCaseHistoryQuery(caseId)

  const formikForm: HistoryForm = useForm<HistorySchema>(
    {
      initialValues: {
        filters: {
          types: [],
          roleEtat: [],
          destinataire: [],
        },
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: historySchema,
    },
  )

  useEffect(() => {
    if (!isFetchingCaseHistory) {
      setFilteredList(history)
      const types = [ ...new Map(history.map((items) => items.type).map((v) => [ v.code, v ])).values() ].sort((a, b) => a.code.localeCompare(b.code)).filter((v) => v.code)
      const roleEtat = [ ...new Set(history.map((value) => value.roleEtat)) ].sort((a, b) => a.localeCompare(b)).filter((v) => v)
      const destinataire = [ ...new Set(history.map((value) => value.destinataire)) ].sort((a, b) => a.localeCompare(b)).filter((v) => v)
      setFilters({ types, roleEtat, destinataire })
    }
  }, [ history, isFetchingCaseHistory ])

  useEffect(() => {
    if (!isFetchingCaseHistory) {
      const { types = [], roleEtat = [], destinataire = []} = formikForm.values.filters
      const { order, orderBy } = formikForm.values.sort

      setFilteredList([ ...history ].sort(getRowsComparator(order, orderBy)).filter((value) =>
        (types.length === 0 || types.includes(value.type.code)) &&
        (roleEtat.length === 0 || roleEtat.includes(value.roleEtat)) &&
        (destinataire.length === 0 || destinataire.includes(value.destinataire)),
      ))
    }
  }, [ formikForm.values.filters, history ])

  const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  const cols: ColumnHeader<HistoriqueSimplifie>[] = [
    {
      id: 'type',
      label: 'Type',
      render: (status: TypeSuiviEnumLabel) => (
        <StatusChipContainer>
          <ColoredSquareChip
            color={getHistoryTypeColor(status.code)}
            className="status-chip"
          >
            {status.libelle}
          </ColoredSquareChip>
        </StatusChipContainer>
      ),
      renderForExport: (status: TypeSuiviEnumLabel) => status.libelle || '',
    },
    {
      id: 'date',
      label: 'Date',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
    },
    {
      id: 'libelle',
      label: 'Libellé',
    },
    {
      id: 'roleEtat',
      label: 'Rôle/État',
      isFiltered: formikForm.values.filters.roleEtat?.some((v) => filters.roleEtat.includes(v)),
      filterComponent: <TableFilterList
        checkedItems={formikForm.values.filters.roleEtat || []}
        items={filters.roleEtat.map((v) => ({ code: v, libelle: v }))}
        onItemsChange={(value) => formikForm.setFieldValue('filters.roleEtat', value)}
        multiple
      />,
    },
    {
      id: 'destinataire',
      label: 'Destinataire',
      isFiltered: formikForm.values.filters.destinataire?.some((v) => filters.destinataire.includes(v)),
      filterComponent: <TableFilterList
        checkedItems={formikForm.values.filters.destinataire || []}
        items={filters.destinataire.map((v) => ({ code: v, libelle: v }))}
        onItemsChange={(value) => formikForm.setFieldValue('filters.destinataire', value)}
        multiple
      />,
    },
    {
      id: 'datePrevuPourLuLeSoldeLe',
      label: 'Prévu pour / lu le / soldé le',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
    },
    {
      id: 'pieceJointe',
      label: 'Actions',
      render: (file: PieceJointeSimplifie, row) => {

        return (
          <ActionContainer>
            {
              file.url &&
                <CustomIconButton
                  Icon={FilePresent}
                  label="Voir le document"
                  onClick={() => window.open(file.url || '', '_blank', 'noreferrer')}
                  variant="outlined"
                  customsize={IconButtonSize.small}
                />
            }
            {
              row?.commentaire &&
                <CustomIconButton
                  Icon={Search}
                  label={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.commentaire) }} /> as unknown as string}
                  variant="outlined"
                  customsize={IconButtonSize.small}
                  labelPlacement="left"
                />
            }
          </ActionContainer>
        )},
    },
  ]

  const onStatusFilterClick = (status: string) => {
    if (formikForm.values.filters.types?.includes(status)) {
      formikForm.setFieldValue('filters.types', formikForm.values.filters.types.filter((stat) => stat !== status))
    } else {
      formikForm.setFieldValue('filters.types', [ ...formikForm.values.filters.types || [], status ])
    }
  }

  return (
    <>
      <LargeTitle>
        Historique
        <Button
          variant="contained"
          onClick={() => setOpenModal(true)}
        >
          Nouvel évènement
        </Button>
      </LargeTitle>
      {
        !workflow ?
          <CircularProgress /> :
          <CaseHistoryWorkflow workflow={workflow} />
      }
      <Form form={formikForm}>
        <FilterMobileContainer>
          <BoldSeparator />
          <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
            Filtres
            <DropDownArrow open={openedFilterMenu} />
          </FormTitleWithArrow>
          <Collapse
            in={openedFilterMenu}
            timeout="auto"
            unmountOnExit
          >
            <CaseHistoryFilters
              isFetching={isFetchingCaseHistory}
              roleList={filters.roleEtat}
              recipientList={filters.destinataire}
            />
            <BoldSeparatorMargin />
          </Collapse>
        </FilterMobileContainer>
        <FormBoldTitle>
          Type d'évènement
        </FormBoldTitle>
        <ScrollableFiltersContainer>
          {
            filters.types.map((filter) => (
              <Chip
                variant={formikForm.values.filters.types?.includes(filter.code) ? 'filled' : 'outlined'}
                key={filter.code}
                onClick={() => onStatusFilterClick(filter.code)}
                color={getHistoryTypeColor(filter.code)}
                bold
              >
                {filter.libelle}
              </Chip>
            ))
          }
        </ScrollableFiltersContainer>
        <LoadingOverlay isLoading={isFetchingCaseHistory}>
          <TableCardContainer>
            <TableCardContentContainer>
              <Table<HistoriqueSimplifie>
                rows={filteredList}
                setRows={(rows) => setFilteredList(rows)}
                colHeaders={cols}
                limit={10}
                defaultOrder={{ order: 'desc', orderBy: 'dateCreation' }}
                sorting={{ setOrder, setOrderBy }}
                removeFilter={(sortId) => formikForm.setFieldValue(`filters.${sortId}`, [])}
              />
            </TableCardContentContainer>
          </TableCardContainer>
          <MobileCardContainer>
            {
              filteredList.map((history, index) => (
                <CaseHistoryMobileTableCard
                  key={`${history.id}-${index}`}
                  history={history}
                />
              ))
            }
          </MobileCardContainer>
        </LoadingOverlay>
      </Form>
      {
        openModal &&
          <CaseHistoryNewEventModal
            caseId={caseId}
            handleClose={() => setOpenModal(false)}
          />
      }
    </>
  )
}

export default CaseHistoryPage
