/* Component imports -------------------------------------------------------- */
import CaseHistoryPage from 'pages/CaseHistoryPage/CaseHistoryPage'
import CaseInvoicePage from 'pages/CaseInvoicePage/CaseInvoicePage'
import CaseMeasurePage from 'pages/CaseMeasurePage/CaseMeasurePage'
import CaseOrderPage from 'pages/CaseOrderPage/CaseOrderPage'
import CaseQuotePage from 'pages/CaseQuotePage/CaseQuotePage'
import CaseTaskPage from 'pages/CaseTaskPage/CaseTaskPage'
import MessagesPage from 'pages/MessagesPage/MessagesPage'
import QuoteInvoicePage from 'pages/QuoteInvoicePages/QuoteInvoicePage'
import MeasurePage from 'pages/MeasurePage/MeasurePage'

/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'components/ColoredSquareChip/ColoredSquareChip'
import {
  EtatDevis,
  WorkflowEtat,
  EtatFacture,
  EtatCommande,
  TypeSuivi,
} from 'API/__generated__/Api'

/* Helper function declarations --------------------------------------------- */
export const getCaseStatusColor = (code: string): ChipColor => {
  switch (code) {
    case 'C':
      return 'blue'
    case 'RA':
      return 'red'
    case 'R':
      return 'purple'
    case 'D':
      return 'orange'
    case 'X':
      return 'salmon'
    case 'M':
      return 'green'
    case 'T':
      return 'grey'
    default:
      return 'yellow'
  }
}

export const getWorkflowStatusColor = (code: WorkflowEtat): ChipColor => {
  switch (code) {
    case WorkflowEtat.EnAttente:
      return 'warning'
    case WorkflowEtat.Fait:
      return 'primary'
    case WorkflowEtat.Brouillon:
      return 'warning'
    case WorkflowEtat.Anomalie:
      return 'red'
    case WorkflowEtat.Annule:
      return 'red'
    case WorkflowEtat.NonFait:
      return 'darkgrey'
    case WorkflowEtat.NonAboutie:
      return 'warning'
    default:
      return 'warning'
  }
}

export const getJalonStatusColor = (code: WorkflowEtat): ChipColor => {
  switch (code) {
    case WorkflowEtat.EnAttente:
      return 'yellow'
    case WorkflowEtat.Fait:
      return 'green'
    case WorkflowEtat.Brouillon:
      return 'orange'
    case WorkflowEtat.Anomalie:
      return 'red'
    case WorkflowEtat.Annule:
      return 'salmon'
    case WorkflowEtat.NonFait:
      return 'grey'
    case WorkflowEtat.NonAboutie:
      return 'yellow'
    default:
      return 'yellow'
  }
}

export const getHistoryTypeColor = (code: TypeSuivi): ChipColor => {
  switch (code) {
    case TypeSuivi.Email:
    case TypeSuivi.SMS:
      return 'green'
    case TypeSuivi.EchangeInterne:
      return 'purple'
    case TypeSuivi.PJ:
    case TypeSuivi.Datable:
    case TypeSuivi.Differe:
      return 'lightblue'
    case TypeSuivi.RendezVous:
      return 'salmon'
    case TypeSuivi.Facture:
    case TypeSuivi.Devis:
    case TypeSuivi.Avoir:
      return 'orange'
    case TypeSuivi.Systeme:
    case TypeSuivi.Divers:
      return 'blue'
    case TypeSuivi.Extranets:
      return 'pink'
    case TypeSuivi.DelaisCharte:
    case TypeSuivi.RendezVousAnnule:
      return 'red'
    case TypeSuivi.Rapport:
    case TypeSuivi.DocumentAlaFrappe:
    case TypeSuivi.TravailAdministratif:
      return 'yellow'
    case TypeSuivi.Info:
    case TypeSuivi.Relance:
      return 'darkorange'
    default:
      return 'yellow'
  }
}

export const getQuoteStatusColor = (code: EtatDevis): ChipColor => {
  switch (code) {
    case EtatDevis.Valide:
      return 'blue'
    case EtatDevis.Refuse:
      return 'red'
    case EtatDevis.Transmis:
      return 'purple'
    case EtatDevis.Retransmis:
      return 'purple'
    case EtatDevis.Brouillon:
      return 'orange'
    case EtatDevis.Regle:
      return 'green'
    default:
      return 'yellow'
  }
}

export const getInvoiceStatusColor = (code: EtatFacture): ChipColor => {
  switch (code) {
    case EtatFacture.Valide:
      return 'blue'
    case EtatFacture.Refuse:
      return 'red'
    case EtatFacture.Envoye:
      return 'purple'
    case EtatFacture.Renvoye:
      return 'purple'
    case EtatFacture.Brouillon:
      return 'orange'
    case EtatFacture.Regle:
      return 'green'
    case EtatFacture.Partiel:
      return 'lightblue'
    case EtatFacture.Annule:
      return 'grey'
    default:
      return 'yellow'
  }
}

export const getOrderStatusColor = (code: EtatCommande): ChipColor => {
  switch (code) {
    case EtatCommande.Transmis:
      return 'purple'
    case EtatCommande.Brouillon:
      return 'orange'
    case EtatCommande.Regle:
      return 'green'
    default:
      return 'yellow'
  }
}

export enum CaseTabType {
  CaseHistory,
  CaseTasks,
  Messages,
  CaseMeasure,
  MeasureEdit,
  MeasureNew,
  CaseQuote,
  QuoteEdit,
  QuoteNew,
  CaseOrder,
  OrderEdit,
  OrderNew,
  CaseInvoice,
  InvoiceEdit,
  InvoiceNew,
  CaseCost,
}

interface CaseTab {
  label: string;
  path: string;
  component: React.ComponentType<object>;
  hide?: boolean;
  props?: object;
  type: CaseTabType;
}

export const caseTabs: CaseTab[] = [
  {
    path: 'historique',
    label: 'Historique',
    component: CaseHistoryPage,
    type: CaseTabType.CaseHistory,
  },
  {
    path: 'taches',
    label: 'Tâches',
    component: CaseTaskPage,
    type: CaseTabType.CaseTasks,
  },
  {
    path: 'messages',
    label: 'Messages',
    component: MessagesPage,
    type: CaseTabType.Messages,
  },
  {
    path: 'metre',
    label: 'Métré',
    component: CaseMeasurePage,
    type: CaseTabType.CaseMeasure,
  },
  {
    path: 'metre/edition/:id',
    label: 'Métré',
    component: MeasurePage,
    hide: true,
    props: { edit: true },
    type: CaseTabType.MeasureEdit,
  },
  {
    path: 'metre/nouveau',
    label: 'Métré',
    component: MeasurePage,
    hide: true,
    type: CaseTabType.MeasureNew,
  },
  {
    path: 'devis',
    label: 'Devis',
    component: CaseQuotePage,
    type: CaseTabType.CaseQuote,
  },
  {
    path: 'devis/edition/:id',
    label: 'Devis',
    component: QuoteInvoicePage,
    hide: true,
    props: { edit: true },
    type: CaseTabType.QuoteEdit,
  },
  {
    path: 'devis/nouveau',
    label: 'Devis',
    component: QuoteInvoicePage,
    hide: true,
    type: CaseTabType.QuoteNew,
  },
  {
    path: 'commande',
    label: 'Commande',
    component: CaseOrderPage,
    type: CaseTabType.CaseOrder,
  },
  {
    path: 'facture',
    label: 'Facture',
    component: CaseInvoicePage,
    type: CaseTabType.CaseInvoice,
  },
  {
    path: 'facture/edition/:id',
    label: 'Facture',
    component: QuoteInvoicePage,
    hide: true,
    props: { edit: true, isInvoice: true },
    type: CaseTabType.InvoiceEdit,
  },
  {
    path: 'facture/nouveau',
    label: 'Facture',
    component: QuoteInvoicePage,
    hide: true,
    props: { isInvoice: true },
    type: CaseTabType.InvoiceNew,
  },
  {
    path: 'cout-de-revient',
    label: 'Coût de revient',
    component: CaseHistoryPage,
    type: CaseTabType.CaseCost,
  },
]

export const periodList = [
  {
    value: new Date('2000-01-01').toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: 'Tout',
  },
  {
    value: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '7 derniers jours',
  },
  {
    value: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '30 derniers jours',
  },
  {
    value: new Date(Date.now() - 93 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '3 derniers mois',
  },
  {
    value: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: "L'année passée",
  },
]
